import Inlet from '@/scripts/bricks/inlet';
import Outlet from '@/scripts/bricks/outlet';
import Filter from '@/scripts/bricks/filter';
import Converter from '@/scripts/bricks/converter';
import Process from '@/scripts/bricks/process';
import SignalOut from '../bricks/signal-out';
import SignalIn from '../bricks/signal-in';
import CustomBrick from '../bricks/customBrick';

export const getBrick = (brickType: any, brickData: any = {}) => {
  const brickSize: number = 100;
  let brick: any;

  if (brickData.brickimage) {
    brick = new CustomBrick({
      height: brickSize,
      width: brickSize,
    }, brickData);
  } else {
    switch (brickType) {
      case 'Inlet': {
        brick = new Inlet(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      case 'Outlet': {
        brick = new Outlet(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      case 'Filter': {
        brick = new Filter(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      case 'Merger': {
        brick = new Filter(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      case 'Converter': {
        brick = new Converter(
          {
            height: brickSize,
            width: 1.4 * brickSize,
          },
          brickData,
        );
        break;
      }
      case 'SignalOut': {
        brick = new SignalOut(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      case 'SignalIn': {
        brick = new SignalIn(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
      default: {
        brick = new Process(
          {
            height: brickSize,
            width: brickSize,
          },
          brickData,
        );
        break;
      }
    }
  }

  return brick;
};
