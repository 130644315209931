/* eslint-disable no-multi-assign */
/*
 Copyright 2018-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import draw2d from 'draw2d';
import * as $ from 'jquery';

import * as types from '@/scripts/editor/types';
import { logging } from '@/scripts/debugger';
import store from '@/store';
import { getBrick } from '@/scripts/editor/bricksOnCanvas';
import { connectionPolicy } from '@/scripts/editor/canvasPolicy';
import { GET_AVAILABLE_BRICKS } from '@/store/types';
import CustomBrick from '@/scripts/bricks/customBrick';

const log = (message: string) => {
  logging('FlowCanvas.ts', message);
};

export default class FlowCanvas extends draw2d.Canvas {
  private readOnly: boolean = false;

  constructor(id: string, width: number, height: number) {
    super(id, width, height);
    log('Contractor is called');

    this.installEditPolicy(connectionPolicy);
  }

  /**
   * Get the brick from the canvas that corresponds to the
   *  given instanceId
   *
   * @param {instanceId} string
   * @memberof FlowCanvas
   */
  // eslint-disable-next-line class-methods-use-this
  getBrickFromCanvas(instanceId: string): CustomBrick | null {
    if (!instanceId) return null;
    let brick = this.figures.data.find((f: any) => f.userData.instanceId === instanceId);
    // in some unknown cases the brickData doest not exist but userData exist. for now
    // check both object
    // TODO: make the brick object name consistance in canvas
    if (!brick) {
      brick = this.figures.data.find((f: any) => f.brickData.instanceId === instanceId);
    }
    return brick;
  }

  onDrop(
    droppedDomNode: any,
    x: number,
    y: number,
    shiftKey: any,
    ctrlKey: any,
    isCopy: boolean = false,
    brickToCopy: any,
  ) : CustomBrick | null {
    log('onDrop is called');

    if (x < 0 || y < 0) {
      // Brick is not dropped inside Canvas
      return null;
    }

    if (this.getReadOnly) {
      return null;
    }

    const brickid: string = isCopy ? brickToCopy.brickData.id : $(droppedDomNode).data('id');

    const brickFromState: types.BrickData = isCopy ? brickToCopy.userData : store.getters[`brick/${GET_AVAILABLE_BRICKS}`].filter(
      (b: types.BrickData) => b.id === brickid,
    )[0];

    const brickname: string = isCopy ? brickToCopy.brickData.brick : $(droppedDomNode).data('name');

    const oldName = isCopy ? brickToCopy.userData.name : '';
    const name = this.getNewBrickName(brickname, isCopy, oldName);

    const bricktype: string = brickFromState.family;
    const { parameters } = brickFromState;
    let parametersClonned = $.extend(true, [], parameters);

    if (brickname === 'APIRequest') {
      // Currently this edge case is hard-coded!!!
      // Because this is the only brick that uses unique Endpoint
      // Therefore, we can't copy it's parameters during brick Copy operation
      parametersClonned = parametersClonned.map((element : any) => {
        const newElement = $.extend(true, {}, element);
        newElement.value = '';
        return newElement;
      });
    }

    const autoscaleMaxInstances: number = brickFromState.autoscale_max_instances || 1;
    const autoscaleQueueLevel: number = brickFromState.autoscale_queue_level || 25;
    const idleTime: number = brickFromState.exit_after_idle_seconds || 10;
    const { inputPorts, outputPorts, brickimage } = brickFromState;

    const bData: types.BrickData = {
      family: bricktype,
      name,
      newName: '',
      type: brickname,
      brick: brickname,
      id: brickid,
      instanceId: '',
      package: brickFromState.packageId,
      description: '',
      parameters: parametersClonned,
      autoscale_max_instances: autoscaleMaxInstances,
      autoscale_queue_level: autoscaleQueueLevel,
      exit_after_idle_seconds: idleTime,
      inputPorts,
      outputPorts,
      brickimage,
      flowId: brickFromState.flowId,
      owner: brickFromState.owner,
      urn: brickFromState.urn || '',
    };
    // Get the brick object
    const brick = getBrick(bricktype, bData);

    brick.setId(name);
    brick.setUserData($.extend(true, {}, bData));
    brick.setPosition(x, y);
    brick.addLabel();
    brick.setDeleteable(false);

    const command: any = new draw2d.command.CommandAdd(this, brick, x, y);
    this.getCommandStack().execute(command);

    // Inform the canvas about the brick

    this.fireEvent('brickAdded', { brick });

    return brick;
  }

  get getReadOnly(): boolean {
    log('getReadOnly is called');
    return this.readOnly;
  }

  set setReadOnly(status: boolean) {
    log('setReadOnly is called');
    this.readOnly = status;
  }

  getNewBrickName(brickname: string, isCopy: boolean, oldName: string) {
    let name = '';
    let count = 0;
    const brickNameDict: any = {};

    this.figures.data.forEach((element: any) => {
      // Map all the brick names already used in canvas
      brickNameDict[element.id] = true;
    });

    if (!isCopy) {
      while (brickNameDict[`${brickname}${count + 1}`]) {
        count += 1;
      }
      name = `${brickname}${count + 1}`;
    } else {
      while (brickNameDict[`${oldName}_${count + 1}`]) {
        count += 1;
      }
      name = `${oldName}_${count + 1}`;
    }

    return name;
  }
}
