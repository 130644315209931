
import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { SchemaType } from '@/scripts/shareModels/schema';
import { Getter, Action } from 'vuex-class';
import * as storeTypes from '@/store/types';
import { BrickInFlow, Connection } from '@/store/flow/models';
import AddFieldInMap from '@/components/editor/portMapping/AddFieldInMap.vue';
import { namespaces } from '@/scripts/namespaces';
import { TypeLibrary } from '@/store/typeLibrary/models';
import { AllTypes, AtomicTypes } from '@/scripts/shareModels/types';
import { findArrayInNestedArray } from './scripts';

const flowNamespace: string = 'flow';

@Component({
  components: {
    AddFieldInMap,
  },
})
export default class ListOfElements extends Vue {
  // Props
  @Prop() schemas!: any;

  @Prop({ default: false }) isSource!: boolean;

  @Prop() mappingRules!: any;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop({ default: true }) showTitle!: boolean;

  @Prop() connection!: Connection;

  @Prop() brick!: BrickInFlow;

  @Prop() port!: any;

  // Getters
  @Getter(storeTypes.GET_BRICKS, { namespace: flowNamespace }) getBricks: any;

  @Getter(storeTypes.GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypesLibrary: any;

  // Actions
  @Action(storeTypes.CREATE_NEW_BUFFER, { namespace: flowNamespace }) createNewBuffer: any;

  @Action(storeTypes.REMOVE_FIELD_FROM_MAP, { namespace: flowNamespace }) removeFieldFromMap: any;

  // Datas

  private elementSelection: SchemaType[] = [];

  private currentLength: number = 0;

  private isAddOrEditMode: boolean = false;

  private ADD_MODE: string = 'ADD_MODE';

  private EDIT_MODE: string = 'EDIT_MODE';

  private ADD_TO_BUFFER: string = 'ADD_TO_BUFFER';

  private schemaToEdit: SchemaType = {
    name: '',
    type: '',
  };

  private item: SchemaType = {
    name: '',
    type: '',
  };

  @Watch('mappingRules')
  onMappingRulesChange(value: any) {
    this.initializeElementSelection();
  }

  // Computed
  get typesLibrary() {
    if (this.getTypesLibrary) {
      const types = this.getTypesLibrary.filter(
        (t: TypeLibrary) => t.type === 'object' || t.type === 'map' || t.type === 'variant',
      );
      return types;
    }
    return [];
  }

  // Methods

  /**
   * Selects an element in the treeview
   * and also informing the parent (MappingDialog)
   * to keep track of the selection
   *
   * @param value - The value that was selected
   */
  select(value: SchemaType) {
    const { name } = value;
    // Uncheck type if something is already checked
    // So that only one type can be checked at a time
    if (this.elementSelection.length - this.currentLength > 1) {
      this.elementSelection.splice(this.elementSelection.length - 2, 1);
    }
    const names: string[] = this.elementSelection.map((selection) => {
      if (selection.name) {
        return selection.name;
      }
      return '';
    });
    const selected: SchemaType | null = names.includes(name) ? value : null;
    this.$emit('selected', selected);
  }

  /**
   * Finds the elements inside the
   * populated JSON that are selected
   * for the mapping rules so that they
   * are shown as CHECKED in the treeview
   */
  initializeElementSelection() {
    if (!this.mappingRules) return;

    this.elementSelection = this.mappingRules.map((rule: string[]) => findArrayInNestedArray(this.schemas, rule, 'elements'));

    this.elementSelection = this.elementSelection.filter((el) => el);

    this.currentLength = this.elementSelection.length;
  }

  updateElementSelection(values: SchemaType[]) {
    this.elementSelection = values;
  }

  isSelected(value: any) {
    if (!this.mappingRules) return false;

    const rules = !!this.mappingRules.find((rule: string[]) => {
      const parentRule = rule.join() === value.parents.join();
      const result = value.parents.concat(new Array(value.name)).join() === rule.join();
      return result || parentRule;
    });

    return rules;
  }

  isChildSelected(value: SchemaType) {
    if (!(value.type === 'object' || value.type === 'record')) {
      return false;
    }
    if (!this.mappingRules) return false;
    return !!this.mappingRules.find(
      (rule: string[]) => rule.includes(value.name) && rule.length > 1,
    );
  }

  /**
   * Finds out if the item in the treeview
   * is extendable by checking if it is:
   *
   * 1. A map
   * 2. Has key of type 'string' in the
   * key-value type
   */
  isExtendable = (item: SchemaType): boolean => {
    if (item && item.key_value_types) {
      return item.type === 'map' && item.key_value_types[0] === 'string';
    }

    return false;
  };

  /**
   * When a field is custom made i.e. added
   * by the user, it can be edited/deleted
   */
  isEditable = (item: SchemaType): boolean => item.isCustom || false;

  /**
   * Opens form for adding element to
   * a map while also setting up
   * necessary props needed by the form
   */
  showForm(mode: string, item: SchemaType) {
    if (mode === this.ADD_MODE) {
      this.item = item;
      this.schemaToEdit = {
        name: '',
        type: '',
        isCustom: true,
      };
      this.isAddOrEditMode = true;
    } else if (mode === this.EDIT_MODE) {
      this.schemaToEdit = item;
      this.isAddOrEditMode = true;
    } else if (mode === this.ADD_TO_BUFFER) {
      this.createNewBuffer(item);
    }
  }

  deleteField({ brick, port, item }: { brick: BrickInFlow; port: any; item: SchemaType }) {
    this.removeFieldFromMap({ brick, port, item });
  }

  // Vue Life cycle
  created() {
    this.initializeElementSelection();
  }

  isCustomType = (item: any) => {
    if (item) {
      const findType = AllTypes.includes(item.type);
      return !!findType;
    }
    return false;
  };

  isFromReservedTypes = (type: TypeLibrary) => AllTypes.includes(type.type);

  isTypeEditable = (type: string) => {
    const editableTypes = ['map', 'variant'];
    return editableTypes.includes(type);
  }
}
