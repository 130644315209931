
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import * as types from '@/store/types';
import Toolbar from '@/components/shared/Toolbar.vue';
import PopupInfo from '@/components/flow/PopupInfo.vue';
import Breadcrumb from '@/components/shared/Breadcrumb.vue';
import { brick } from '@/store/bricks';

const namespaceFlow: string = 'flow';
const namespaceUser: string = 'user';

@Component({
  components: {
    Toolbar,
    PopupInfo,
    Breadcrumb,
  },
})
export default class ToolbarEditor extends Vue {
  // Props
  @Prop({ default: 'Flow' }) flowName!: string;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop({ default: null }) canvas!: any;

  // Getters
  @Getter(types.GET_RUN_STATE, { namespace: namespaceFlow }) getRunState: any;

  @Getter(types.GET_IS_DIRTY, { namespace: namespaceFlow }) getFlowIsDirty: any;

  @Getter(types.GET_FLOW, { namespace: namespaceFlow }) getFlow: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  @Getter(types.GET_UPDATE_BRICK_LOADING, { namespace: namespaceFlow }) isBrickUpdateLoding: any;

  private name: string = '';

  private popupInfoDialog: boolean = false;

  private flowID: string = '';

  private shouldShowGrids: boolean = true;

  private loading = false;

  private deleteBrickLoading = false;

  private copyBrickLoading = false;

  created() {
    this.flowID = this.getFlow.id;
  }

  get playButtonColor() {
    return this.$vuetify.theme.dark ? '#3C3C3C' : 'rgba(0, 0, 0, 0.12)';
  }

  private toggleCanvasGrids() {
    this.shouldShowGrids = !this.shouldShowGrids;
    this.$emit('show-grid-line', this.shouldShowGrids);
  }

  private showLogsClicked() {
    this.$emit('show-logs-clicked');
  }

  private deleteElementClicked() {
    this.$emit('delete-element-clicked');
  }

  private copyElementClicked() {
    this.$emit('copy-element-clicked');
  }

  private zoomIn() {
    this.$emit('zoom-in');
  }

  private zoomOut() {
    this.$emit('zoom-out');
  }

  private resetZoom() {
    this.$emit('reset-zoom');
  }

  private undo() {
    this.$emit('undo');
  }

  private redo() {
    this.$emit('redo');
  }

  private ifAnyBrickSelected() {
    if (!this.canvas) {
      return false;
    }
    const brickSelections = this.canvas.selection.all.data.filter(
      (selection: any) => selection.cssClass === 'draw2d_SetFigure',
    );

    return brickSelections.length > 0;
  }

  private connectionStyle = localStorage.getItem('ConnectionRouterStyle') || 'circuit';

  private changeConnectionStyle(newStyle: string) {
    localStorage.setItem('ConnectionRouterStyle', newStyle);
    this.$emit('change-connection-style', newStyle);
  }
}
