
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MapppingSuggestion extends Vue {
  @Prop() hasMappingRule!: any;

  @Prop() mappingSuggestion!: any;

  acceptMatching(source: any, target: any) {
    this.$emit('acceptMatching', source, target);
  }
}
