
import { validateParameter } from '@/scripts/editor/helper';
import { namespaces } from '@/scripts/namespaces';
import { STRING_LIST_ELEMENT_REGEX } from '@/scripts/shared';
import { Parameter } from '@/store/bricks/models';
import { GET_USER } from '@/store/types';
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class StringListEditor extends Vue {
  // Props
  @Prop() parameter!: Parameter;

  @Prop() index!: number;

  @Prop() flowOwner!: string;

  @Prop() flowId!: string;

  @Prop() instanceId!: string;

  @Prop() isParameterRequired!: Function;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  // Getters
  @Getter(GET_USER, { namespace: namespaces.USER }) getUser: any;

  // Data
  private valid = false;

  private items: string[] = [];

  private parameterCopy: Parameter = $.extend(true, {}, this.parameter);

  // Vue Life Cycle Hooks
  mounted() {
    this.parameterCopy = $.extend(true, {}, this.parameter);

    if (this.parameterCopy.value) {
      // Break parameter value into a list
      this.items = this.parameterCopy.value.split('|');
    }
  }

  // Methods
  // REQUIRED RULE
  isRequired(value: string) {
    if (this.isParameterRequired(this.parameterCopy)) {
      return !!value || this.$t('required_field');
    }

    return true;
  }

  // REGEX RULE FROM THE BRICK ITSELF
  checkBrickRegex(value:string) {
    const { parameterCopy } = this;
    parameterCopy.value = value;
    if (!validateParameter(parameterCopy)) {
      return `${String(this.$t('match_regex'))} ${this.parameterCopy.constraints?.validation}`;
    }

    return true;
  }

  // REGEX RULE DISALLOWING PIPES
  checkForPipes(value: string) {
    return STRING_LIST_ELEMENT_REGEX.test(value) || this.$t('list_element_invalid');
  }

  private close() {
    this.$emit('close');
  }

  /**
   * Adds a new item in the list
   */
  private appendItem() {
    this.items.push('');
  }

  /**
   * Removes item from the list
   * at a given index
   *
   * @param index - The index to
   * splice at
   */
  private removeItem(index: number) {
    this.items.splice(index, 1);
  }

  /**
   * Joins the parameter with a piped symbol
   * so it can be saved in the brick's parameter's
   * value
   */
  updateParameter() {
    // join all values with a " | " (pipe) symbol
    const joinedValues = this.items.flatMap((item) => item).join('|');

    const { form }: any = this.$refs;

    form.validate();

    if (this.valid) {
      // set the parameter value
      this.parameterCopy.value = joinedValues;

      // asks the parent (PropertyPane) to update the brick in DB
      this.$emit('update-parameter', this.parameterCopy, this.index, true);

      this.close();
    }
  }
}
