/*
 Copyright 2018-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/

import draw2d from 'draw2d';
import * as $ from 'jquery';
import addPorts from '@/scripts/bricks/addPorts';
import { DecoratedInputPort, DecoratedOutputPort } from '@/scripts/bricks/portDecorator';
import { shapeFeedback } from '@/scripts/bricks/policy';
import { SVG_TO_BASE64_PREFIX } from '../shared';

export default class CustomBrick extends draw2d.SetFigure {
  constructor(attr: any, brickData: any = {}) {
    super(
      $.extend(
        {
          stroke: 0,
          bgColor: null,
          width: 80,
          height: 40,
        },
        attr,
      ),
    );

    this.brickData = brickData;

    addPorts(this, brickData);
    shapeFeedback(this);

    this.persistPorts = false;
  }

  createPort(name: any, type: any, locator: any) {
    let newPort = null;
    let count = 0;

    switch (type) {
      case 'input':
        newPort = new DecoratedInputPort();
        newPort.setCustomValue(name);
        newPort.setPortType('input');

        count = this.inputPorts.getSize();
        break;
      case 'output':
        newPort = new DecoratedOutputPort();
        newPort.setCustomValue(name);
        newPort.setPortType('output');

        count = this.outputPorts.getSize();
        break;
      default:
        throw new Error(`Unknown type [${type}] of port requested`);
    }

    newPort.setName(type + count);

    this.addPort(newPort, locator);
    // relayout the ports
    this.setDimension(this.width, this.height);

    this.layoutPorts();

    return newPort;
  }

  createSet() {
    const set: any = this.canvas.paper.set();

    // Create a SVG pattern for the background image
    const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
    pattern.id = draw2d.util.UUID.create();
    pattern.setAttribute('x', '0');
    pattern.setAttribute('y', '0');
    pattern.setAttribute('height', '1');
    pattern.setAttribute('width', '1');
    pattern.setAttribute('patternContentUnits', 'objectBoundingBox');

    // Create image element with brick's custom image
    const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    image.setAttributeNS(null, 'height', '1');
    image.setAttributeNS(null, 'width', '1');
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `${SVG_TO_BASE64_PREFIX}${this.brickData.brickimage}`);
    image.setAttributeNS(null, 'x', '0');
    image.setAttributeNS(null, 'y', '0');
    image.setAttributeNS(null, 'preserveAspectRatio', 'xMinYMin');

    // Add image to the SVG pattern
    pattern.appendChild(image);

    // Add pattern to SVG defs so that shapes can access it
    this.canvas.paper.defs.appendChild(pattern);

    // BoundingBox
    let shape: any = this.canvas.paper.path('M0,0 L80,0 L80,40 L0,40');
    shape.attr({ stroke: 'none', 'stroke-width': 0, fill: 'none' });
    shape.data('name', 'BoundingBox');

    // Add to set
    set.push(shape);

    // Create Square to hold custom image
    shape = this.canvas.paper.rect(0, 0, 100, 100);
    shape.node.setAttribute('fill', `url(#${pattern.id})`);
    shape.data('name', 'Rectangle');

    // Add to set
    set.push(shape);

    return set;
  }

  addLabel() {
    const label = new draw2d.shape.basic.Text({
      text: this.userData.name,
      color: '#EEEEEE',
      fontFamily: 'Verdana',
    });
    this.add(label, new draw2d.layout.locator.TopLocator());
  }

  updateLabel(name: string) {
    this.removeLabel();
    const label = new draw2d.shape.basic.Text({
      text: name,
      color: '#EEEEEE',
      fontFamily: 'Verdana',
    });
    this.add(label, new draw2d.layout.locator.TopLocator());
  }

  removeLabel() {
    const removeMe = (this.children.data[0] || {}).figure;
    if (removeMe) {
      const cmd = new draw2d.command.CommandDelete(removeMe);
      this.canvas.getCommandStack().execute(cmd);
    }
  }
}
