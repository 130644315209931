import draw2d from 'draw2d';

export const MarkerStateBFigure = draw2d.shape.layout.HorizontalLayout.extend({
  NAME: 'MarkerStateBFigure',

  /**
   * @param attr
   */
  init(attr: any, setter: any, getter: any) {
    /* eslint no-underscore-dangle: 0 */
    this._super(
      $.extend(
        {
          bgColor: '#FFFFFF',
          stroke: 1,
          color: '#00bcd4',
          radius: 2,
          padding: {
            left: 3,
            top: 3,
            bottom: 3,
            right: 8,
          },
          gap: 5,
        },
        attr,
      ),
      setter,
      getter,
    );

    this.label = new draw2d.shape.basic.Label({
      text: attr.text,
      resizeable: false,
      stroke: 0,
      padding: 0,
      fontSize: 10,
      fontColor: '#303030',
    });
    this.add(this.label);
    // don't catch the mouse events. This is done by the parent container
    this.label.hitTest = () => false;
    this.label.addCssClass('cursorPointer');

    this.hitTest = () => false;

    this.portType = 'input';
  },

  setPortType(type: any) {
    this.portType = type;
  },

  setText(text: any) {
    this.label.setText(text);
  },

  setTick(flag: any) {
    this.stickTick.attr({ bgColor: flag ? '#00bcd4' : '#f0f0f0' });
  },

  getStickTickFigure() {
    return this.stickTick;
  },

  getLabelFigure() {
    return this.label;
  },

  /**
   * @method
   *
   *
   * @template
   * */
  repaint(attributes: any) {
    if (this.repaintBlocked === true || this.shape === null) {
      return;
    }

    attributes = attributes || {}; // eslint-disable-line no-param-reassign

    attributes.path = this.calculatePath(); // eslint-disable-line no-param-reassign

    /* eslint no-underscore-dangle: 0 */
    this._super(attributes);
  },

  /**
   * @method
   *
   * Override the default rendering of the HorizontalLayout, which is a simple
   * rectangle. We want an arrow.
   */
  createShapeElement() {
    return this.canvas.paper.path(this.calculatePath());
  },

  calculatePath() {
    const arrowLength = 8;

    this.vertices = new draw2d.util.ArrayList();

    const w = this.width;
    const h = this.height;
    const pos = this.getAbsolutePosition();

    if (this.portType === 'input') {
      this.vertices.add(new draw2d.geo.Point(pos.x, pos.y));
      this.vertices.add(new draw2d.geo.Point(pos.x + w - arrowLength, pos.y));

      this.vertices.add(new draw2d.geo.Point(pos.x + w, pos.y + h / 2));

      this.vertices.add(new draw2d.geo.Point(pos.x + w - arrowLength, pos.y + h));
      this.vertices.add(new draw2d.geo.Point(pos.x, pos.y + h));
    } else {
      this.vertices.add(new draw2d.geo.Point(pos.x, pos.y));
      this.vertices.add(new draw2d.geo.Point(pos.x + w - arrowLength, pos.y));

      this.vertices.add(new draw2d.geo.Point(pos.x + w - arrowLength, pos.y + h));
      this.vertices.add(new draw2d.geo.Point(pos.x, pos.y + h));

      this.vertices.add(new draw2d.geo.Point(pos.x - arrowLength, pos.y + h / 2));
    }

    const radius = this.getRadius();
    const path = [];
    // hard corners
    //
    if (radius === 0) {
      const length = this.vertices.getSize();
      let p = this.vertices.get(0);
      path.push('M', (p.x | 0) + 0.5, ' ', (p.y | 0) + 0.5); // eslint-disable-line no-bitwise
      for (let i = 1; i < length; i += 1) {
        p = this.vertices.get(i);
        path.push('L', (p.x | 0) + 0.5, ' ', (p.y | 0) + 0.5); // eslint-disable-line no-bitwise
      }
      path.push('Z');
    } else {
      let length = this.vertices.getSize();
      let start = this.vertices.first();
      let end = this.vertices.last();
      if (start.equals(end)) {
        length -= 1;
        end = this.vertices.get(length - 1);
      }
      const begin = draw2d.geo.Util.insetPoint(start, end, radius);
      path.push('M', (begin.x | 0) + 0.5, ',', (begin.y | 0) + 0.5); // eslint-disable-line no-bitwise
      for (let i = 0; i < length; i += 1) {
        start = this.vertices.get(i);
        end = this.vertices.get((i + 1) % length);
        const modStart = draw2d.geo.Util.insetPoint(start, end, radius);
        const modEnd = draw2d.geo.Util.insetPoint(end, start, radius);
        path.push(
          'Q',
          start.x,
          ',',
          start.y,
          ' ',
          // eslint-disable-next-line no-bitwise
          (modStart.x | 0) + 0.5,
          ', ',
          // eslint-disable-next-line no-bitwise
          (modStart.y | 0) + 0.5,
        ); // eslint-disable-line no-bitwise
        path.push('L', (modEnd.x | 0) + 0.5, ',', (modEnd.y | 0) + 0.5); // eslint-disable-line no-bitwise
      }
    }

    return path.join('');
  },
});

/**
 * This is only the mouseover reactive shape. A little bit smaller than the visible shape
 *
 * Or you can display this shape with opacity of 0.2 to indicate that this is a reactive area.
 */
export const MarkerStateAFigure = draw2d.shape.basic.Label.extend({
  NAME: 'MarkerStateAFigure',

  /**
   * @param attr
   */
  init(attr: any, setter: any, getter: any) {
    /* eslint no-underscore-dangle: 0 */
    this._super(
      $.extend(
        {
          padding: {
            left: 5,
            top: 2,
            bottom: 2,
            right: 10,
          },
          bgColor: null,
          stroke: 1,
          color: null,
          fontColor: null,
          fontSize: 10,
        },
        attr,
      ),
      setter,
      getter,
    );
    this.hitTest = () => false;
  },
});

/**
 * The markerFigure is the left/right hand side annotation for a DecoratedPort.
 *
 */
export const MarkerFigure = draw2d.shape.layout.VerticalLayout.extend({
  NAME: 'MarkerFigure',

  init(attr: any, setter: any, getter: any) {
    const _this = this;

    this.isMouseOver = false; // indicator if the mouse is over the element
    this.stick = false; // indicator if the stateBFigure should always be visible
    this.defaultValue = true; // current selected default value for the decoration

    /* eslint no-underscore-dangle: 0 */
    this._super(
      $.extend(
        {
          stroke: 0,
        },
        attr,
      ),
      setter,
      getter,
    );

    // figure if the decoration is not permanent visible (sticky note)
    this.add((this.stateA = new MarkerStateAFigure({ text: 'X' })));
    // figure if the decoration permanent visible
    this.add((this.stateB = new MarkerStateBFigure({ text: 'X' })));

    this.on('mouseenter', (emitter: any, event: any) => {
      _this.onMouseOver(true);
    });

    this.on('mouseleave', (emitter: any, event: any) => {
      _this.onMouseOver(false);
    });

    this.setDefaultValue(true);
    this.onMouseOver(false);
  },

  setPortType(type: any) {
    this.stateB.setPortType(type);
  },

  onMouseOver(flag: any) {
    this.isMouseOver = flag;

    if (this.visible === false) {
      return; // silently
    }

    if (this.stick === true) {
      this.stateA.setVisible(false);
      this.stateB.setVisible(true);
    } else {
      this.stateA.setVisible(!this.isMouseOver);
      this.stateB.setVisible(this.isMouseOver);
    }
  },

  setVisible(flag: any) {
    /* eslint no-underscore-dangle: 0 */
    this._super(flag);

    // update the hover/stick state of the figure
    this.onMouseOver(this.isMouseOver);

    return this;
  },

  setStick(flag: any) {
    this.stick = flag;
    this.onMouseOver(this.isMouseOver);

    // the port has only a default value if the decoration is visible
    this.parent.setValue(flag ? this.defaultValue : null);

    this.stateB.setTick(this.getStick());

    return this;
  },

  getStick() {
    return this.stick;
  },

  setText(text: any) {
    this.stateB.setText(text);

    return this;
  },

  setDefaultValue(value: any) {
    this.defaultValue = value;
    this.setText(this.defaultValue === true ? 'High' : 'Low');

    // only propagate the value to the parent if the decoration permanent visible
    //
    if (this.stick === true) {
      this.parent.setValue(this.defaultValue);
    }
  },

  setCustomValue(value: any) {
    this.setText(value);
  },
});

export const DecoratedInputPort = draw2d.InputPort.extend({
  init(attr: any, setter: any, getter: any) {
    /* eslint no-underscore-dangle: 0 */
    this._super(attr, setter, getter);

    this.decoration = new MarkerFigure();

    this.add(this.decoration, new draw2d.layout.locator.LeftLocator({ margin: 8 }));

    this.setValue(true);
  },

  useDefaultValue() {
    this.decoration.setStick(true);
  },

  setCustomValue(value: any) {
    this.decoration.setCustomValue(value);
  },

  setPortType(type: any) {
    this.decoration.setPortType(type);
  },
});

export const DecoratedOutputPort = draw2d.OutputPort.extend({
  init(attr: any, setter: any, getter: any) {
    /* eslint no-underscore-dangle: 0 */
    this._super(attr, setter, getter);

    this.decoration = new MarkerFigure();

    this.add(this.decoration, new draw2d.layout.locator.RightLocator({ margin: 8 }));

    this.setValue(true);
  },

  useDefaultValue() {
    this.decoration.setStick(true);
  },

  setCustomValue(value: any) {
    this.decoration.setCustomValue(value);
  },

  setPortType(type: any) {
    this.decoration.setPortType(type);
  },
});
