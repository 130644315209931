
import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { Connection, IBuffer, MappingRule } from '@/store/flow/models';
import { validate } from '@/scripts/shareModels/mapping_validation';
import { SchemaType } from '@/scripts/shareModels/schema';
import { findArrayInNestedArray } from '@/components/editor/portMapping/scripts';

import { BUFFER_MAPPING_RULE } from '@/scripts/shared';

const flowNamespace: string = 'flow';

@Component
export default class ListOfBuffer extends Vue {
  // Props
  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() usedElementsInMapping!: string[];

  @Prop() connection!: Connection;

  @Prop() mappingRules!: any;

  // Getters
  @Getter(types.GET_SOURCE_TO_BUFFER, { namespace: flowNamespace }) getSource: any;

  @Getter(types.GET_CONNECTIONS, { namespace: flowNamespace }) getConnections: any;

  // Actions
  @Action(types.CREATE_NEW_BUFFER, { namespace: flowNamespace }) cancelCreateBuffer: any;

  @Action(types.ADD_TO_BUFFER, { namespace: flowNamespace }) addToBuffer: any;

  @Action(types.DELETE_BUFFER, { namespace: flowNamespace }) deleteBuffer: any;

  // Data
  private currentLength: number = 0;

  private bufferName: string = '';

  private elementSelection: SchemaType[] = [];

  private bufferElements: IBuffer[] = [];

  private validForm: boolean = true;

  private rules: any = [
    (value: string) => {
      const errorMessage = this.$t('buffer_name_exist');
      if (this.isBufferUnique(value)) {
        return errorMessage;
      }
      return true;
    },
  ];

  // Computed

  // Watchers

  @Watch('getSource', { deep: true })
  onSourceChanged(value: any) {
    if (value) {
      this.bufferName = this.getSource.name;
    }
  }

  @Watch('mappingRules')
  onMappingRulesChange(value: any) {
    this.initializeElementSelection();
  }

  @Watch('getConnections', { deep: true })
  onConnectionsChanged() {
    this.setBufferElements();
    this.initializeElementSelection();
  }
  // Methods

  saveBuffer() {
    const { form }: any = this.$refs;
    if (!form.validate()) {
      return;
    }

    this.addToBuffer({
      connection: this.connection,
      schema: this.getSource,
      bufferName: this.bufferName,
    });

    this.$emit('selected-source', null);
  }

  /**
   * Selects an element in the treeview
   * and also informing the parent (MappingDialog)
   * to keep track of the selection
   *
   * @param value - The value that was selected
   */
  select(value: SchemaType, bufferId: string) {
    const { name } = value;

    // Uncheck type if something is already checked
    // So that only one type can be checked at a time
    if (this.elementSelection.length - this.currentLength > 1) {
      this.elementSelection.splice(this.elementSelection.length - 2, 1);
    }

    const selected = this.elementSelection.map((selection: any) => selection.name).includes(name)
      ? value
      : null;
    this.$emit('selected', { selected, bufferId });
  }

  /**
   * Finds the elements inside the
   * populated JSON that are selected
   * for the mapping rules so that they
   * are shown as CHECKED in the treeview
   */
  initializeElementSelection() {
    if (!this.bufferElements || !this.mappingRules) return;
    const bufferValue = this.bufferElements.map((b: any) => b.value);

    this.elementSelection = this.mappingRules.map((rule: string[]) => findArrayInNestedArray(bufferValue, rule, 'elements'));

    this.elementSelection = this.elementSelection.filter((el) => el);

    this.currentLength = this.elementSelection.length;
  }

  updateElementSelection(values: SchemaType[]) {
    this.elementSelection = values;
  }

  // Vue Life cycle
  created() {
    this.setBufferElements();
    this.initializeElementSelection();
  }

  isSelected(value: any) {
    const rules = !!this.mappingRules.find((rule: string[]) => {
      const parentRule = rule.join() === value.parents.join();
      const result = value.parents.concat(new Array(value.name)).join() === rule.join();
      return result || parentRule;
    });

    return rules;
  }

  isChildSelected(value: SchemaType) {
    if (!(value.type === 'object' || value.type === 'record' || value.type === 'map')) {
      return false;
    }
    return !!this.mappingRules.find(
      (rule: string[]) => rule.includes(value.name) && rule.length > 1,
    );
  }

  setBufferElements(): any {
    if (!this.connection) return;

    this.bufferElements = Object.values(this.connection.buffer);
  }

  isBufferUnique(name: string) {
    if (!name) return false;
    const foundName = this.bufferElements
      .map((b: any) => b.value.name.toLowerCase())
      .includes(name.toLowerCase());
    return foundName;
  }
}
