
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import {
  CONSTANT_ID_PREFIX,
  FLOAT_REGEX,
  INT_REGEX,
  MESSAGE_TYPE_REGEX,
  UINT_REGEX,
} from '@/scripts/shared';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';
import * as nanoid from 'nanoid';
import {
  AtomicTypes, Floats, Integers, UIntegers,
} from '@/scripts/shareModels/types';
import { namespaces } from '@/scripts/namespaces';
import { TypeLibrary } from '@/store/typeLibrary/models';
import { generateParentChainForSchema } from '../portMapping/scripts';

@Component
export default class ConstantBuilder extends Vue {
  // Props
  @Prop() constantToEdit!: Constant;

  // Getters
  @Getter(types.GET_CONSTANTS, { namespace: namespaces.FLOW }) constants: any;

  @Getter(types.GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) typesFromLibrary: any;

  // Actions
  @Action(types.ADD_CONSTANT, { namespace: namespaces.FLOW }) addConstant: any;

  @Action(types.EDIT_CONSTANT, { namespace: namespaces.FLOW }) editConstant: any;

  // Data
  private valid: boolean = false;

  // v-model when constant has a single value
  private value: string = '';

  private valueRules: any = {
    self: this,
    required: (v: any) => !!v || this.$t('required_field'),
    validValue(type: string) {
      const { self } = this;
      return (v: string) => self.validateConstant(v, type);
    },
  };

  // v-model when constant has elements (e.g. from type library)
  private values: Constant[] = [];

  private name: string = '';

  private nameRules: any = {
    required: (v: string) => !!v || this.$t('required_field'),
    textRule: (v: string) => MESSAGE_TYPE_REGEX.test(v) || this.$t('message_type_invalid'),
    conflictRule: (v: string) => this.checkNameConflict(v),
  };

  private selectedType: SchemaType = { name: '', type: '', id: '' };

  private isEditMode: boolean = false;

  private isTypeFromLibrary: boolean = false;

  // Computed
  get types(): SchemaType[] {
    // remove non atomic types from the type library list
    const nonAtomicTypesFromLibrary: SchemaType[] = this.typesFromLibrary.filter(
      (el: SchemaType) => !AtomicTypes.includes(el.type) && el.type !== 'list',
    );

    // Remove 'variant' from atomic types
    // Wrap the remaining atomic types into a SchemaType
    // Append the types from the library
    return AtomicTypes.slice(0, -1)
      .map((type) => ({ id: type, name: type, type } as SchemaType))
      .concat(...nonAtomicTypesFromLibrary);
  }

  // Vue Lifecycle Hooks
  mounted() {
    this.setConstant();
  }

  // Watchers
  @Watch('constantToEdit')
  onConstantChange() {
    this.setConstant();
  }

  // Methods
  /**
   * Prepares the form for the user to
   * either add or edit a constant
   */
  setConstant() {
    if (this.constantToEdit) {
      this.name = this.constantToEdit.name;
      this.value = this.constantToEdit.value;

      this.selectedType = this.types.find((el) => el.type === this.constantToEdit.type) || {
        name: '',
        type: '',
        id: '',
      };

      this.isEditMode = true;

      // check if type is from library or type has elements to show them in the form
      this.selectType();

      this.$nextTick(() => {
        const { form }: any = this.$refs;

        form.validate();
      });
    } else {
      this.resetForm();
      this.isEditMode = false;
    }
  }

  /**
   * Empties all fields in the form
   */
  resetForm() {
    this.name = '';
    this.value = '';
    this.selectedType = { name: '', type: '', id: '' };
    this.values = [];
    this.isTypeFromLibrary = false;
  }

  /**
   * Validates the constant if it is
   * of the chosen atomic type or not
   *
   * @param value - the value to validate
   * @param type - the type according to which the
   * validation needs to be carried out
   */
  validateConstant(value: string, type: string) {
    if (value) {
      if (value.length < 1 || type === 'string') {
        return true;
      }

      switch (true) {
        // Boolean values
        case type === 'bool':
          return (
            value.toLowerCase().trim() === 'true'
            || value.toLowerCase().trim() === 'false'
            || this.$t('invalid_boolean')
          );

        // Integer values
        case Integers.includes(type):
          return INT_REGEX.test(value) || this.$t('invalid_integer');

        // unsigned Integer values
        case UIntegers.includes(type):
          return UINT_REGEX.test(value) || this.$t('invalid_uinteger');

        // Float values
        case Floats.includes(type):
          return FLOAT_REGEX.test(value) || this.$t('invalid_float');

        default: {
          return true;
        }
      }
    }

    return true;
  }

  /**
   * Adds or Edits the constant
   * into the store and resets the form
   */
  addOrEditConstant() {
    const { form }: any = this.$refs;

    form.validate();

    if (this.valid) {
      // we need to do the parse and stringify so that
      // values are available otherwise VueJS's reactivity
      // blocks you from accessing them
      const values: Constant[] = JSON.parse(JSON.stringify(this.values));

      // Initialize Constant
      const constant: Constant = {
        id: this.isEditMode ? this.constantToEdit.id : `${CONSTANT_ID_PREFIX}${nanoid.nanoid()}`,
        name: this.name,
        value: this.isTypeFromLibrary ? '' : this.value,
        mappedConnections: this.isEditMode ? this.constantToEdit.mappedConnections : [],
        type: this.selectedType.type,
        typeLibraryName: this.selectedType.name,
        isTypeLibrary: this.isTypeFromLibrary,
        hideCheckBox: this.isEditMode ? this.constantToEdit.hideCheckBox : false,
        elements: this.isTypeFromLibrary ? values : [],
      };

      if (this.isTypeFromLibrary) {
        generateParentChainForSchema(constant as SchemaType, []);
      }

      if (this.isEditMode) {
        // Edit in the store
        this.editConstant({ constant });
      } else {
        // Add to the store
        this.addConstant({ constant });
      }

      // Reset form and validation
      form.reset();
      this.resetForm();
      this.$emit('cancel');
    }
  }

  /**
   * Check Name Conflict with other Constants
   */
  checkNameConflict(name: string) {
    if (this.constants && this.constants.length > 0) {
      const isValid = this.constants
        .filter((c: Constant) => c.name !== (this.constantToEdit ? this.constantToEdit.name : '')) // ignore current name when editing
        .findIndex((c: Constant) => c.name === name);

      if (isValid >= 0) {
        return this.$t('name_already_in_use');
      }
    }

    return true;
  }

  /**
   * When a type for the constant is selected
   * the form needs to morph and accomodate
   * types coming in from the type library
   */
  selectType() {
    if (this.selectedType && this.selectedType.id) {
      const type: SchemaType = this.typesFromLibrary.find(
        (t: TypeLibrary) => t.id === this.selectedType.id,
      );

      if (type) {
        if (type.elements && type.elements.length > 0) {
          // setup values to show in the form
          if (this.isEditMode) {
            this.values = $.extend(true, [], this.constantToEdit.elements) || [];
          } else {
            this.values = type.elements.map((element) => {
              const constant: Constant = {
                id: `${CONSTANT_ID_PREFIX}${nanoid.nanoid()}`,
                name: element.name,
                type: element.type,
                value: '',
                parents: [],
              };

              return constant;
            });
          }

          // Show elements of type from the library in the form
          this.isTypeFromLibrary = true;
        }
      } else {
        // Show single value as type is atomic
        this.isTypeFromLibrary = false;
      }
    }
  }

  /**
   * Custom filter to search for types in the autocorrect field
   */
  searchType = (item: SchemaType, queryText: string) => {
    const text = item.name.toLowerCase();
    const searchText = queryText.toLowerCase();

    return text.indexOf(searchText) > -1;
  };
}
