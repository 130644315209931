/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import draw2d from 'draw2d';
import {
  Flow, BrickInFlow, Connection,
} from '@/store/flow/models';
import {
  Parameter, InstalledBrick, BrickLocation,
} from '@/store/bricks/models';

import * as EditTypes from '@/scripts/editor/types';
import Process from '@/scripts/bricks/process';
import Inlet from '@/scripts/bricks/inlet';
import Outlet from '@/scripts/bricks/outlet';
import Filter from '@/scripts/bricks/filter';
import Converter from '@/scripts/bricks/converter';
import { logging } from '@/scripts/debugger';
import store from '@/store';
import { DELETE_CONNECTION, GET_FLOW } from '@/store/types';
import SignalIn from '../bricks/signal-in';
import SignalOut from '../bricks/signal-out';
import CustomBrick from '../bricks/customBrick';
import { getConnectionRouterStyle } from './canvasPolicy';

const log = (message: string) => {
  logging('flowConverter.ts', message);
};
/**
 *FLow class for loading and saving flows from/to canvas
 *
 * @export
 * @class ConvertFlow
 */
export default class FlowConverter {
  flow:Flow

  bricks : BrickInFlow[];

  connections: Connection[];

  brickSize: number;

  brick: any;

  constructor(flow:Flow, connections:Connection[]) {
    log('Constructor called');
    this.flow = flow;
    this.bricks = flow.config.bricks;
    this.connections = connections;
    this.brickSize = 100;
  }

  /**
 * update flow and the state of the flow
 *
 * @param {*} canvas
 * @memberof ConvertFlow
 * */

  update(canvas: any) {
    // Create and delete bricks
    this.updateBricks(canvas);
    // Delete connections
    this.updateConnections(canvas);
  }

  /**
 * Update the bricks on the the canvas
 *
 * @param {*} canvas
 * @memberof ConvertFlow
 */
  updateBricks(canvas: any) {
    log(`updateBricks is called and has ${canvas.figures.length} figures`);
    // persist InstalledBrick addition
    const { figures } = canvas;
    let canvasBricks: string[] = [];
    for (const brick of figures.data) {
      const location = this.setBrickLocation(brick, canvas);

      this.updateBrickParameters(brick.id, brick.userData, location.x, location.y);
      if (brick.userData.newName.length > 0 && brick.userData.newName !== brick.id) {
        // this.renameConnections(canvas, brick.id, brick.userData.newName);
        brick.id = brick.userData.newName;
        brick.updateLabel(brick.userData.newName);
      }
      canvasBricks = [...canvasBricks, brick.id];
    }
    // persist brick removal
    function onCanvas(element:BrickInFlow, index:number, array: BrickInFlow[]) {
      const id = canvasBricks.findIndex((cb) => cb === element.name);
      return id >= 0;
    }

    if (this.flow.config.bricks) {
      this.flow.config.bricks = this.flow.config.bricks.filter(onCanvas);
    }

    // and also removal of  associated connections
    function bricksExist(element:Connection, index: number, array: Connection[]) {
      const sourceID = canvasBricks.findIndex((cb) => cb === element.source.brick);
      const targetID = canvasBricks.findIndex((cb) => cb === element.target.brick);
      return sourceID >= 0 && targetID >= 0;
    }
    if (this.connections) {
      const filterConn = this.connections.filter(bricksExist);
      this.connections = filterConn;
    }
  }

  /**
 * Update the InstalledBrick's parameters
 *
 * @param {*} view
 * @param {EditTypes.BrickData} userData
 * @param {number} xLocation
 * @param {number} yLocation
 * @memberof ConvertFlow
 */
  updateBrickParameters(view: any, userData: EditTypes.BrickData,
    xLocation: number, yLocation: number) {
    log(`updateBrickParameters is called, userData is ${userData}`);

    // persist InstalledBrick parameters
    const cParameters: Parameter[] = [];
    // set changed parameters
    if (userData.parameters && userData.parameters.length > 0) {
      for (const parameter of userData.parameters) {
        const newParameter: Parameter = {
          name: parameter.name,
          value: parameter.value,
          type: parameter.type,
          editor: parameter.editor,
          unlocked: parameter.unlocked,
          constraints: parameter.constraints,
        };
        cParameters.push(newParameter);
      }
    }

    const uBrick: BrickInFlow = {
      instanceId: userData.instanceId,
      id: userData.id,
      name: userData.newName ? userData.newName : view,
      description: userData.description,
      brick: userData.type,
      family: userData.family,
      urn: userData.urn,
      parameters: cParameters,
      location: { x: xLocation, y: yLocation },
      autoscale_max_instances: userData.autoscale_max_instances,
      autoscale_queue_level: userData.autoscale_queue_level,
      exit_after_idle_seconds: userData.exit_after_idle_seconds,
      inputPorts: userData.inputPorts,
      outputPorts: userData.outputPorts,
      brickimage: userData.brickimage,
      flowId: userData.flowId,
      owner: userData.owner,
    };

    let index: number = -1;
    if (this.flow.config.bricks) {
      // check if the brick already exists
      index = this.flow.config.bricks.findIndex((b) => b.name === view);
    }

    if (index < 0) {
      //  if not, append it
      if (!this.flow.config.bricks) {
        this.flow.config.bricks = [uBrick];
      } else {
        this.flow.config.bricks = [...this.flow.config.bricks, uBrick];
      }
    } else {
      // it it exists,
      // first remove the existing brick
      uBrick.instanceId = this.flow.config.bricks[index].instanceId;
      this.flow.config.bricks.splice(index, 1);
      // and then append the updated one
      this.flow.config.bricks = [...this.flow.config.bricks, uBrick];
    }
  }

  /**
   * Update connections on the canvas
   *
   * @param {*} canvas
   * @memberof ConvertFlow
   */
  updateConnections(canvas: any) {
    log('updateConnections is called');
    // persist Connection addition
    const { lines } = canvas;
    const canvasConnections : Connection[] = [];

    // persist connection removal
    // todo use brick instance Id
    function onCanvas(element:Connection, index:number, array: Connection[]) {
      const id = canvasConnections.findIndex((cc) => (cc.source.brick === element.source.brick)
        && (cc.target.brick === element.target.brick));
      return id >= 0;
    }
    if (this.connections) {
      this.connections = this.connections.filter(onCanvas);
    }
  }

  /**
 * Draw the flow on the canvas
 *
 * @param {BrickInFlow[]} bricks
 * @param {Connection[]} connections
 * @param {*} canvas
 * @memberof ConvertFlow
 */

  drawFlow(availableBricks: InstalledBrick[],
    bricks: BrickInFlow[],
    connections: Connection[], canvas: any) {
    // allow for reloading:
    if (bricks) {
      this.bricks = bricks;
      for (const brick of this.bricks) {
        const inputPorts = brick.inputPorts
        || availableBricks.find((b) => b.id === brick.id)?.inputPorts || [];
        const outputPorts = brick.outputPorts
        || availableBricks.find((b) => b.id === brick.id)?.outputPorts || [];
        this.drawBrick(brick, inputPorts, outputPorts, canvas);
      }
    }
    if (connections) {
      this.connections = connections;
      for (const connection of this.connections) {
        this.drawConnection(connection, canvas);
      }
    }
  }

  /**
 * Drawing the Bricks on the canvas
 *
 * @param {BrickInFlow} brick
 * @param {*} canvas
 * @memberof ConvertFlow
 */
  drawBrick(brick: BrickInFlow, inputPorts: any, outputPorts:any, canvas: any) {
    log(`drawBrick is called, and drawing brick ${brick.name}`);
    const bData: EditTypes.BrickData = {
      family: brick.family,
      type: brick.brick,
      brick: brick.brick,
      id: brick.id,
      instanceId: brick.instanceId,
      package: '',
      name: brick.name,
      newName: '',
      parameters: brick.parameters || [],
      autoscale_max_instances: brick.autoscale_max_instances,
      autoscale_queue_level: brick.autoscale_queue_level,
      exit_after_idle_seconds: brick.exit_after_idle_seconds,
      inputPorts,
      outputPorts,
      brickimage: brick.brickimage || '',
      description: brick.description,
      flowId: this.flow.id,
      owner: this.flow.owner,
      urn: brick.urn,
    };

    if (brick.brickimage) {
      this.brick = new CustomBrick({
        height: this.brickSize,
        width: this.brickSize,
      }, bData);
    } else {
      switch (brick.family) {
        case 'Inlet': {
          this.brick = new Inlet({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
        case 'Outlet': {
          this.brick = new Outlet({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
        case 'Selector': {
          this.brick = new Process(
            {
              height: this.brickSize,
              width: this.brickSize,
            },
            bData,
          );
          break;
        }
        case 'Merger': {
          this.brick = new Filter(
            {
              height: this.brickSize,
              width: this.brickSize,
            },
            bData,
          );
          break;
        }
        case 'Converter': {
          this.brick = new Converter({
            height: this.brickSize,
            width: 1.4 * this.brickSize,
          }, bData);
          break;
        }
        case 'Filter': {
          this.brick = new Filter({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
        case 'SignalOut': {
          this.brick = new SignalOut({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
        case 'SignalIn': {
          this.brick = new SignalIn({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
        default: {
          this.brick = new Process({
            height: this.brickSize,
            width: this.brickSize,
          }, bData);
          break;
        }
      }
    }

    this.brick.setId(brick.name);
    this.brick.setUserData(bData);
    this.brick.addLabel();
    this.brick.setDeleteable(false);
    if (brick && brick.location) {
      this.brick.setPosition(
        brick.location.x * canvas.paper.width,
        brick.location.y * canvas.paper.height,
      );
    }

    const brickFound: any = canvas.getFigure(brick.name);
    if (canvas !== undefined && brickFound === null) {
      canvas.add(this.brick);
    }
  }

  /**
 * Drawing the Bricks connection on the canvas
 *
 * @param {Connection} connection
 * @param {*} canvas
 * @memberof ConvertFlow
 */
  // eslint-disable-next-line class-methods-use-this
  async drawConnection(connection: Connection, canvas: any) {
    log('drawConnection is called');
    const sourceBrick: any = canvas.getBrickFromCanvas(connection.source.brick);
    const targetBrick: any = canvas.getBrickFromCanvas(connection.target.brick);

    if (sourceBrick && targetBrick) {
      const ConnectionRouterStyle = getConnectionRouterStyle();
      const brickConnection: any = new draw2d.Connection({
        router: new ConnectionRouterStyle(),
        color: 'rbg(0,0,0)',
      });

      const portsOnSourceBrick: any = sourceBrick.getPorts();
      for (const port of portsOnSourceBrick.data) {
        if (port.userData.portData.type === 'output' && port.name === connection.source.port.id) {
          brickConnection.setSource(port);
        }
      }

      const portsOnTargetBrick: any = targetBrick.getPorts();
      for (const port of portsOnTargetBrick.data) {
        if (port.userData.portData.type === 'input' && port.name === connection.target.port.id) {
          brickConnection.setTarget(port);
        }
      }

      const { lines } = canvas;
      const lineFound = lines.data.findIndex(
        (l: any) => l.sourcePort.name === connection.source.port.id
          && l.targetPort.name === connection.target.port.id
          && l.sourcePort.parent.id === connection.source.brick
          && l.targetPort.parent.id === connection.target.brick,
      );

      if (lineFound === -1) {
        brickConnection.cssClass = 'brick-connection';

        brickConnection.setDeleteable(false);

        canvas.add(brickConnection);

        // Fire connection click event
        brickConnection.onClick = () => {
          canvas.fireEvent('onClickConn', { brickConnection });
        };

        brickConnection.onMouseEnter = () => {
          brickConnection.removeCssClass('brick-connection');
          brickConnection.addCssClass('brick-connection-fat');
        };

        brickConnection.onMouseLeave = () => {
          brickConnection.removeCssClass('brick-connection-fat');
          brickConnection.addCssClass('brick-connection');
        };
      }
    } else {
      // The connection has no target/source brick and must be deleted
      const flow: any = store.getters[`flow/${GET_FLOW}`];

      await store.dispatch(`flow/${DELETE_CONNECTION}`,
        { configID: flow.config.connectionConfigId, connectionID: connection.id },
        { root: true });
    }
  }

  /**
 * Before saving the brick into DB some need to complete the brick
 * model, such as flow id, permissions and owner
 *
 * @param {EditTypes.BrickData} brick
 * @return {InstalledBrick}
 * @memberof FlowConverter
 */
  getBrick(brick: EditTypes.BrickData): InstalledBrick {
    const { flow } = this;
    const newBrick: InstalledBrick = brick;
    newBrick.flowId = flow.id;
    newBrick.brick = brick.type || brick.brick;
    newBrick.owner = flow.owner;
    newBrick.workspaceId = flow.workspaceId;

    return newBrick;
  }

  setBrickLocation = (brick: any, canvas: any): BrickLocation => {
    let xLocation: number = 0.0;
    let yLocation: number = 0.0;
    const newBrick = brick;
    if (newBrick.x < 0) newBrick.x = 0;
    if (newBrick.y < 0) newBrick.y = 0;

    xLocation = newBrick.x / canvas.paper.width;
    yLocation = newBrick.y / canvas.paper.height;
    const location: BrickLocation = { x: xLocation, y: yLocation };
    return location;
  }
}
