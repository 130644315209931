/*
 Copyright 2018-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/

import draw2d from 'draw2d';
import * as $ from 'jquery';
import addPorts from '@/scripts/bricks/addPorts';
import { shapeFeedback } from '@/scripts/bricks/policy';
import { DecoratedOutputPort } from './portDecorator';

export default class SignalIn extends draw2d.SetFigure {
  constructor(attr: any, brickData: any = {}) {
    super(
      $.extend(
        {
          stroke: 0,
          bgColor: null,
          width: 80,
          height: 80,
        },
        attr,
      ),
    );

    // Port
    addPorts(this, brickData);
    shapeFeedback(this);

    this.persistPorts = false;
  }

  createPort(name: any, type: any, locator: any) {
    let newPort = null;
    let count = 0;

    newPort = new DecoratedOutputPort();
    newPort.setCustomValue(name);
    newPort.setPortType('output');

    count = this.outputPorts.getSize();

    newPort.setName(type + count);

    this.addPort(newPort, locator);
    // relayout the ports
    this.setDimension(this.width, this.height);

    this.layoutPorts();

    return newPort;
  }

  createSet() {
    const set: any = this.canvas.paper.set();

    // BoundingBox
    let shape: any = this.canvas.paper.path('M0,0 L80,0 L80,80 L0,80');
    shape.attr({ stroke: 'none', 'stroke-width': 0, fill: 'none' });
    shape.data('name', 'BoundingBox');
    set.push(shape);

    // Triangle
    shape = this.canvas.paper.path('M0,40 L80,0 L80,80 Z');
    shape.attr({
      stroke: '#303030',
      'stroke-width': 1,
      fill: '#EEEEEE',
      dasharray: null,
      opacity: 1,
    });
    shape.data('name', 'Triangle');
    set.push(shape);

    // Triangle
    shape = this.canvas.paper.path('M0,40 L60,0 L60,80 Z');
    shape.attr({
      stroke: '#303030',
      'stroke-width': 1,
      fill: '#EEEEEE',
      dasharray: null,
      opacity: 0,
    });
    shape.data('name', 'Triangle');
    set.push(shape);

    return set;
  }

  addLabel() {
    const label = new draw2d.shape.basic.Text({
      text: this.userData.name,
      color: '#EEEEEE',
      fontFamily: 'Verdana',
    });
    this.add(label, new draw2d.layout.locator.TopLocator());
  }

  updateLabel(name: string) {
    this.removeLabel();
    const label = new draw2d.shape.basic.Text({
      text: name,
      color: '#EEEEEE',
      fontFamily: 'Verdana',
    });
    this.add(label, new draw2d.layout.locator.TopLocator());
  }

  removeLabel() {
    const removeMe = (this.children.data[0] || {}).figure;
    if (removeMe) {
      const cmd = new draw2d.command.CommandDelete(removeMe);
      this.canvas.getCommandStack().execute(cmd);
    }
  }
}
