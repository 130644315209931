
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import ListOfConstants from '@/components/editor/constants/ListOfConstants.vue';
import ListOfBuffer from '@/components/editor/buffer/ListOfBuffer.vue';
import ListOfElements from '@/components/editor/portMapping/ListOfElements.vue';
import MappedElements from '@/components/editor/portMapping/MappedElements.vue';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';
import * as types from '@/store/types';
import {
  Connection, IBuffer, MappedElement, MappingRule,
} from '@/store/flow/models';
import { resetValidation, validate, matchTypes } from '@/scripts/shareModels/mapping_validation';
import { namespaces } from '@/scripts/namespaces';
import {
  BUFFER_ID_PREFIX,
  PORT_MAPPING_RULE,
  BUFFER_MAPPING_RULE,
  CONSTANT_MAPPING_RULE,
} from '@/scripts/shared';
import MappingSuggestion from '@/components/editor/portMapping/MappingSuggestion.vue';
import { BufferSelection } from './models';

const namespaceFlow = 'flow';
const MAX_KEY = 32000;

@Component({
  components: {
    ListOfConstants,
    ListOfBuffer,
    ListOfElements,
    MappedElements,
    MappingSuggestion,
  },
})
export default class MappingDialogue extends Vue {
  // Props

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop() sourceBrick!: any;

  @Prop() targetBrick!: any;

  @Prop() sourcePort!: any;

  @Prop() targetPort!: any;

  @Prop() flowConverter!: any;

  @Prop() getUserData!: Function;

  // Actions
  @Action(types.UPDATE_CONNECTION, { namespace: namespaceFlow }) updateConnection: any;

  @Action(types.DELETE_CONNECTION, { namespace: namespaceFlow }) deleteConnection: any;

  @Action(types.VALIDATE_CONSTANT, { namespace: namespaceFlow }) validateConstant: any;

  @Action(types.VALIDATE_BUFFER_ELEMENT, { namespace: namespaceFlow }) validateBufferElement: any;

  @Action(types.RESET_VALIDATION_BUFFER, { namespace: namespaceFlow }) resetBufferValidation: any;

  @Action(types.RESET_CONSTANT_VALIDATION, { namespace: namespaceFlow })
  resetConstantValidation: any;

  @Action(types.SAVE_FLOW, { namespace: namespaceFlow }) updateFlow: any;

  @Action(types.UPDATE_BRICK, { namespace: namespaceFlow }) updateBrick: any;

  @Action(types.FETCH_TYPES_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) fetchTypesLibrary: any;

  // Getters

  @Getter(types.GET_FLOW, { namespace: namespaceFlow }) getFlow: any;

  @Getter(types.GET_CONNECTIONS, { namespace: namespaceFlow }) getConnections: any;

  @Getter(types.GET_CONSTANTS, { namespace: namespaceFlow }) constants: any;

  // Data

  private constantSelection: Constant | null = null;

  private constantElementMap: string[] = [];

  private bufferSelection: BufferSelection | null = null;

  private bufferElementMap: string[] = [];

  private sourcePortSelection: SchemaType | null = null;

  private sourcePortElementMap: string[] = [];

  private sourceComponentKey: number = 0;

  private targetPortSelection: SchemaType | null = null;

  private targetPortElementMap: string[] = [];

  private targetComponentKey: number = 0;

  private isMapping: boolean = false;

  private isShowMappedElements: boolean = true;

  private mappedElement: MappedElement = {
    rule: {
      type: '',
      sourceFields: [],
      targetFields: [],
      constant: {
        id: '',
        name: '',
        value: '',
        type: '',
      },
    },
    usedElements: [],
  };

  private loading: boolean = false;

  private isMounted: boolean = false;

  private deleteDialog: boolean = false;

  private deleteLoading: boolean = false;

  private mappingSuggestion: any = [];

  private BUFFER_ID_PREFIX = BUFFER_ID_PREFIX;

  private isAutoMapping: boolean = false;

  // Watchers

  // Vue Lifecycle
  async mounted() {
    const { workspaceId } = this.$route.params;

    await this.fetchTypesLibrary(workspaceId);

    this.suggestMappingRule();

    this.isMounted = true;
  }

  // Computed

  get connection(): Connection | null {
    const { sourceBrick } = this;
    const { targetBrick } = this;

    if (sourceBrick.instanceId === undefined) {
      return null;
    }
    if (targetBrick.instanceId === undefined) {
      return null;
    }

    return this.getConnections.find(
      (c: Connection) => c.source.brick === sourceBrick.instanceId
        && c.target.brick === targetBrick.instanceId
        && c.source.port.id === this.sourcePort.name
        && c.target.port.id === this.targetPort.name,
    );
  }

  /**
   * Returns all the mapping rules
   * for this connection
   */
  get mappingRules(): any {
    const connection: Connection = this.getConnections?.find(
      (c: Connection) => c.source.brick === this.sourceBrick.instanceId
        && c.target.brick === this.targetBrick.instanceId
        && c.source.port.id === this.sourcePort.name
        && c.target.port.id === this.targetPort.name,
    );

    return connection?.mapping.map((element) => element.rule);
  }

  get usedElementsInMapping(): string[] {
    if (this.getConnections.length <= 0) {
      return [];
    }

    const connection: Connection = this.getConnections?.find(
      (c: Connection) => c.source.brick === this.sourceBrick.instanceId
        && c.target.brick === this.targetBrick.instanceId
        && c.source.port.id === this.sourcePort.name
        && c.target.port.id === this.targetPort.name,
    );

    if (!connection) {
      return [];
    }

    return connection.mapping.map((map) => map.usedElements.map((el) => el)).flat();
  }

  /**
   * Returns mapping rules of the source brick
   */
  get sourceMappingRules(): any {
    if (this.mappingRules && this.mappingRules.length <= 0) {
      return [];
    }
    return this.mappingRules
      ?.filter(
        (rule: MappingRule) => rule.type
        !== CONSTANT_MAPPING_RULE && rule.type !== BUFFER_MAPPING_RULE,
      )
      .map((rule: MappingRule) => rule.sourceFields);
  }

  /**
   * Returns mapping rules of the target brick
   */
  get targetMappingRules(): any {
    if (this.mappingRules && this.mappingRules.length <= 0) {
      return [];
    }

    return this.mappingRules?.map((rule: MappingRule) => rule.targetFields);
  }

  /**
   * Returns mapping rules of the buffer
   */
  get bufferMappingRules(): any {
    if (this.mappingRules && this.mappingRules.length <= 0) {
      return [];
    }

    // make a deep copy to avoid splicing original mapping rules
    const result = $.extend(
      true,
      [],
      this.mappingRules
        ?.filter((rule: MappingRule) => rule.type === BUFFER_MAPPING_RULE)
        .map((rule: MappingRule) => rule.sourceFields),
    );

    return result;
  }

  /**
   * Returns the populated JSON for the
   * source brick as an array for the
   * v-treeview to render
   */
  get sourcePortPopulatedJSON(): SchemaType[] {
    const populatedPort: SchemaType[] = [];

    populatedPort.push(this.sourcePort.populatedJSON);

    return populatedPort;
  }

  /**
   * Changes the key so the source brick
   * treeview can re-render
   */
  set sourcePortPopulatedJSON(value: SchemaType[]) {
    if (this.sourceComponentKey < MAX_KEY) {
      this.sourceComponentKey += 1;
    } else {
      this.sourceComponentKey = 0;
    }
  }

  /**
   * Returns the populated JSON for the
   * source brick as an array for the
   * v-treeview to render
   */
  get targetPortPopulatedJSON(): SchemaType[] {
    const populatedPort: SchemaType[] = [];

    populatedPort.push(this.targetPort.populatedJSON);

    return populatedPort;
  }

  /**
   * Changes the key so the target brick
   * treeview can re-render
   */
  set targetPortPopulatedJSON(value: SchemaType[]) {
    if (this.targetComponentKey < MAX_KEY) {
      this.targetComponentKey += 1;
    } else {
      this.targetComponentKey = 0;
    }
  }

  get hasMappingRule(): boolean {
    return this.mappingRules && this.mappingRules.length <= 0 && this.mappingSuggestion.length > 0;
  }

  // Methods

  /**
   * Selects and saves the checked element
   * from the source brick
   */
  selectSourceElement(value: any) {
    if (value) {
      // uncheck constant so that
      // only one element remains checked
      if (this.constantSelection) {
        this.clearElementInConstants();
        this.constantSelection = null;
      }

      // uncheck buffer element so that
      // only one element remains checked
      if (this.bufferSelection) {
        this.clearElementInBuffer();
        this.bufferSelection = null;
      }

      // if the target has no selection
      // then validate to disable unmatching types
      if (!this.targetPortSelection) {
        this.targetPortPopulatedJSON = [validate(value, this.targetPortPopulatedJSON[0])];
      }

      this.sourcePortSelection = value || null;

      // prepare element for mapping if both have selection
      if (this.sourcePortSelection && this.targetPortSelection) {
        this.prepareElementsForMapping();

        this.isMapping = true;
      }
    } else {
      // reset validation on the target side
      this.targetPortPopulatedJSON = [resetValidation(this.targetPortPopulatedJSON[0])];

      this.sourcePortSelection = null;
    }
  }

  /**
   * Selects and saves the checked element
   * from the target brick
   */
  selectTargetElement(value: any) {
    if (value) {
      // if the source has no selection
      // then validate to disable unmatching types
      if (!this.sourcePortSelection) {
        this.sourcePortPopulatedJSON = [validate(this.sourcePortPopulatedJSON[0], value, true)];
      }

      // if the constants have no selection
      // then validate to disable unmatching types
      if (!this.constantSelection) {
        this.constants
          .map((constant: Constant) => this.validateConstant({ constant, targetSchema: value }));
      }

      // if the constants have no selection
      // then validate to disable unmatching types
      if (!this.bufferSelection) {
        this.validateBufferElement({
          connection: this.connection,
          targetSchema: value,
        });
      }

      this.targetPortSelection = value;

      // prepare element for mapping if both have selection
      if (
        (this.sourcePortSelection && this.targetPortSelection)
        || (this.constantSelection && this.targetPortSelection)
        || (this.bufferSelection && this.targetPortSelection)
      ) {
        this.prepareElementsForMapping();

        this.isMapping = true;
      }
    } else {
      // reset validation on the source side
      this.sourcePortPopulatedJSON = [resetValidation(this.sourcePortPopulatedJSON[0])];

      // reset validation on the constants
      this.constants.map((constant: Constant) => this.resetConstantValidation({ constant }));

      // reset validation for buffers
      this.resetBufferValidation({
        connection: this.connection,
      });

      this.targetPortSelection = null;
    }
  }

  /**
   * Selects and saves the checked element
   * from the constants
   */
  selectedConstant(value: any) {
    if (value) {
      // uncheck source brick so that
      // only one element remains checked
      if (this.sourcePortSelection) {
        this.clearElementInSourcePort();
        this.sourcePortSelection = null;
      }

      // uncheck buffer element so that
      // only one element remains checked
      if (this.bufferSelection) {
        this.clearElementInBuffer();
        this.bufferSelection = null;
      }

      // if the target has no selection
      // then validate to disable unmatching types
      if (!this.targetPortSelection) {
        this.targetPortPopulatedJSON = [validate(value, this.targetPortPopulatedJSON[0])];
      }

      this.constantSelection = value;

      // prepare element for mapping if both have selection
      if (this.constantSelection && this.targetPortSelection) {
        this.prepareElementsForMapping();

        this.isMapping = true;
      }
    } else {
      // reset validation on the target side
      this.targetPortPopulatedJSON = [resetValidation(this.targetPortPopulatedJSON[0])];

      this.constantSelection = null;
    }
  }

  /**
   * Selects and saves the checked element
   * from the buffer
   */
  selectBufferElement({ selected, bufferId }: { selected: SchemaType; bufferId: string }) {
    if (selected && bufferId) {
      // uncheck constant AND source so that
      // only one element remains checked
      if (this.constantSelection) {
        this.clearElementInConstants();
        this.constantSelection = null;
      }

      if (this.sourcePortSelection) {
        this.clearElementInSourcePort();
        this.sourcePortSelection = null;
      }

      // if the target has no selection
      // then validate to disable unmatching types
      if (!this.targetPortSelection) {
        this.targetPortPopulatedJSON = [validate(selected, this.targetPortPopulatedJSON[0])];
      }

      this.bufferSelection = { value: selected, bufferId } || null;

      // prepare element for mapping if both have selection
      if (this.bufferSelection && this.targetPortSelection) {
        this.prepareElementsForMapping();

        this.isMapping = true;
      }
    } else {
      // reset validation on the target side
      this.targetPortPopulatedJSON = [resetValidation(this.targetPortPopulatedJSON[0])];

      this.bufferSelection = null;
    }
  }

  /**
   * Prepares the elements for mapping by forming
   * a string array of the map for the source
   * and target brick
   *
   * @example: sourcePortElementMap will have [post, author, name]
   */
  prepareElementsForMapping() {
    if (this.constantSelection) {
      /**
       * a mapping rule with constants need the
       * type and then value
       *
       * @example [_constant, int32, 88]
       */
      // check if this is still ok
      this.constantElementMap.splice(
        this.constantElementMap.length,
        0,
        this.constantSelection.type,
        this.constantSelection.isTypeLibrary
          ? this.constantSelection.name
          : this.constantSelection.value,
      );
    }

    if (this.bufferSelection && this.bufferSelection.bufferId) {
      /**
       * a mapping rule with buffer element need
       * parent chain and name of the buffer
       *
       * @example [ Request, bufferName]
       */
      const mapping: string[] = [];

      const { value } = this.bufferSelection;

      if (value.parents && value.parents.length > 0) {
        const map: string[] = value.parents.concat(new Array(value.name));

        map.unshift(...mapping);

        this.bufferElementMap.push(...map);
      } else {
        mapping.push(value.name);

        this.bufferElementMap.push(...mapping);
      }
    }

    /**
     * @name BrickPortMappingRule
     * a mapping rule for brick ports need
     * the parent chain and name of the element
     * selected
     *
     * @example [post, author, name, first_name]
     */
    if (this.sourcePortSelection) {
      if (this.sourcePortSelection.parents && this.sourcePortSelection.parents.length > 0) {
        const map: string[] = this.sourcePortSelection.parents.concat(
          new Array(this.sourcePortSelection.name),
        );
        this.sourcePortElementMap.push(...map);
      } else {
        this.sourcePortElementMap.push(this.sourcePortSelection.name);
      }
    }

    /**
     * @see BrickPortMappingRule
     */
    if (this.targetPortSelection) {
      if (this.targetPortSelection.parents && this.targetPortSelection.parents.length > 0) {
        this.targetPortElementMap.push(
          ...this.targetPortSelection.parents.concat(new Array(this.targetPortSelection.name)),
        );
      } else {
        this.targetPortElementMap.push(this.targetPortSelection.name);
      }
    }
  }

  /**
   * Informs the child component <MappedElements>
   * to trigger the mapping to save it in the
   * state
   */
  confirmMapping() {
    this.isAutoMapping = false;
    if (this.sourcePortSelection?.id && this.targetPortSelection?.id) {
      this.mappedElement.usedElements.push(
        ...[this.sourcePortSelection.id, this.targetPortSelection.id],
      );
      this.mappedElement.rule.sourceFields = this.sourcePortElementMap;
      this.mappedElement.rule.targetFields = this.targetPortElementMap;
      this.mappedElement.rule.type = PORT_MAPPING_RULE;
    } else if (this.constantSelection?.id && this.targetPortSelection?.id) {
      this.mappedElement.usedElements.push(
        ...[this.constantSelection.id, this.targetPortSelection.id],
      );
      this.mappedElement.rule.type = CONSTANT_MAPPING_RULE;
      this.mappedElement.rule.sourceFields.push(this.constantSelection.id);
      this.mappedElement.rule.targetFields = this.targetPortElementMap;
      this.mappedElement.rule.constant = this.constantSelection;
    } else if (this.bufferSelection?.bufferId && this.targetPortSelection?.id) {
      this.mappedElement.usedElements.push(
        ...[this.bufferSelection.bufferId, this.targetPortSelection.id],
      );
      this.mappedElement.rule.type = BUFFER_MAPPING_RULE;
      this.mappedElement.rule.bufferId = this.bufferSelection.bufferId;
      this.mappedElement.rule.sourceFields = this.bufferElementMap;
      this.mappedElement.rule.targetFields = this.targetPortElementMap;
    }

    // cancel the mapping overlay
    this.cancelMapping();
  }

  /**
   * Unchecks the selected elements from
   * both bricks and turns off mapping
   * overlay
   */
  cancelMapping() {
    this.isAutoMapping = false;

    this.sourcePortSelection = null;
    this.sourcePortElementMap = [];

    this.targetPortSelection = null;
    this.targetPortElementMap = [];

    this.constantSelection = null;
    this.constantElementMap = [];

    this.bufferSelection = null;
    this.bufferElementMap = [];

    this.sourcePortPopulatedJSON = [resetValidation(this.sourcePortPopulatedJSON[0])];
    this.targetPortPopulatedJSON = [resetValidation(this.targetPortPopulatedJSON[0])];
    this.constants.map((constant: Constant) => this.resetConstantValidation({ constant }));
    this.resetBufferValidation({ connection: this.connection });

    this.clearElementInSourcePort();
    this.clearElementInTargetPort();
    this.clearElementInConstants();
    this.clearElementInBuffer();

    this.isMapping = false;
  }

  /**
   * Resets the v-model for the checkboxes
   * inside the ListOfElements for the source port
   */
  clearElementInSourcePort() {
    const { sourcePort }: any = this.$refs;
    if (sourcePort && sourcePort.elementSelection) {
      sourcePort.elementSelection.pop();
    }
  }

  /**
   * Resets the v-model for the checkboxes
   * inside the ListOfElements for the target port
   */
  clearElementInTargetPort() {
    const { targetPort }: any = this.$refs;
    if (targetPort && targetPort.elementSelection) {
      targetPort.elementSelection.pop();
    }
  }

  /**
   * Clear the currently mapped element
   */
  clearMappedElement() {
    this.mappedElement = {
      rule: {
        type: '',
        sourceFields: [],
        targetFields: [],
        constant: {
          id: '',
          name: '',
          value: '',
          type: '',
        },
      },
      usedElements: [],
    };
  }

  /**
   * Resets the v-model for the checkboxes
   * inside the ListOfConstants
   */
  clearElementInConstants() {
    const { brickConstants }: any = this.$refs;
    if (brickConstants && brickConstants.selectedConstants) {
      brickConstants.selectedConstants.pop();
    }
  }

  /**
   * Resets the v-model for the checkboxes
   * inside the ListOfBuffer
   */
  clearElementInBuffer() {
    const { buffer }: any = this.$refs;
    if (buffer && buffer.elementSelection) {
      buffer.elementSelection.pop();
    }
  }

  /**
   * Saves the changes in the connection and
   * as well as the bricks then telling the
   * parent to close the dialog
   */
  async closeMappingDialog() {
    // only save when flow is not readonly
    // and being accessed by the owner
    if (!this.isFlowReadOnly) {
      this.loading = true;

      const updatedSourceBrick = this.getUserData(this.sourceBrick.name, this.sourceBrick);
      const updatedTargetBrick = this.getUserData(this.targetBrick.name, this.targetBrick);

      // update the source brick
      const sourceBrick = new Promise((resolve, reject) => {
        try {
          this.updateBrick({ brick: updatedSourceBrick, isShowSuccess: false });

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      // update the target brick
      const targetBrick = new Promise((resolve, reject) => {
        try {
          this.updateBrick({ brick: updatedTargetBrick, isShowSuccess: false });

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      // update the flow
      const flow = new Promise((resolve, reject) => {
        try {
          this.updateFlow({
            flow: this.getFlow,
            showSnackbar: false,
          });

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      // update the connection
      const connection = new Promise((resolve, reject) => {
        try {
          this.updateConnection({
            configID: this.getFlow.config.connectionConfigId,
            connection: this.connection,
          });

          resolve(true);
        } catch (error) {
          reject(error);
        }
      });

      await Promise.all([sourceBrick, targetBrick, flow, connection])
        .then(() => {
        // update the variables saved in the canvas
          this.$emit('save-settings-clicked');

          this.loading = false;

          // tell EditDraw2d to close dialog
          this.$emit('close');
        });
    } else {
      // tell EditDraw2d to close dialog
      this.$emit('close');
    }
  }

  /**
   * Deletes the connection from the flow and informs
   * the parent to close the dialog AND remove
   * connection from the canvas
   */
  async deleteConnectionFromFlow() {
    this.deleteLoading = true;

    await this.deleteConnection({
      configID: this.getFlow.config.connectionConfigId,
      connectionID: this.connection?.id,
    });

    this.$emit('delete-connection', false);

    this.deleteLoading = false;
    this.deleteDialog = false;
    this.$emit('close');
  }

  /**
   * Check the source and target port when there is no existing
   * mapping on connection and if the types match, suggest the mapping rule
   * to the user
   */
  suggestMappingRule() {
    if (this.mappingRules && this.mappingRules.length > 0) {
      return;
    }
    const mapOptions: any = [];
    this.targetPortPopulatedJSON.forEach((target) => {
      this.sourcePortPopulatedJSON.forEach((source) => {
        const isMached = matchTypes(source, target);
        if (isMached) {
          const newMached = {
            source,
            target,
          };
          mapOptions.push(newMached);
        }
      });
    });
    this.mappingSuggestion = mapOptions;
  }

  acceptMatching(source: any, target: any) {
    this.selectSourceElement(source);
    this.selectTargetElement(target);
  }
}
