import draw2d from 'draw2d';
import $ from 'jquery';
import { logging } from '@/scripts/debugger';
import store from '@/store';

const log = (message: string) => {
  logging('canvasPolicy.ts', message);
};
export const gridPolicy: any = new draw2d.policy.canvas.SnapToGridEditPolicy(10);

export const getConnectionRouterStyle = () => (localStorage.getItem('ConnectionRouterStyle') === 'spline'
  ? draw2d.layout.connection.SplineConnectionRouter
  : draw2d.layout.connection.CircuitConnectionRouter);

export const connectionPolicy: any = new draw2d.policy.connection.DragConnectionCreatePolicy({
  /**
   * @method
   *
   * @param {draw2d.Figure} figure the shape below the mouse or null
   * @param {Number} x the x-coordinate of the mouse down event
   * @param {Number} y the y-coordinate of the mouse down event
   * @param {Boolean} shiftKey true if the shift key has been pressed during this event
   * @param {Boolean} ctrlKey true if the ctrl key has been pressed during the event
   */
  async onMouseUp(canvas: any, x: number, y: number, shiftKey: any, ctrlKey: any) {
    log('onMouseUp is called');

    if (this.mouseDraggingElement !== null) {
      const de = this.mouseDraggingElement;
      const ct = this.currentTarget;
      // start CommandStack transaction
      canvas.getCommandStack().startTransaction();

      de.onDragEnd(x, y, shiftKey, ctrlKey);

      // notify all installed policies
      //
      if (ct) {
        de.editPolicy.each((i: any, e: any) => {
          if (e instanceof draw2d.policy.port.PortFeedbackPolicy) {
            e.onHoverLeave(canvas, de, ct);
          }
        });
      }

      de.editPolicy.each((i: any, e: any) => {
        if (e instanceof draw2d.policy.figure.DragDropEditPolicy) {
          e.onDragEnd(canvas, de, x, y, shiftKey, ctrlKey);
        }
      });

      // Do not connect if flow is running
      if (canvas.getReadOnly) {
        canvas.getCommandStack().commitTransaction();
        this.currentDropTarget = null;
        this.mouseDraggingElement = null;

        return;
      }

      // Reset the drag&drop flyover information
      //
      this.currentTarget = null;
      de.isInDragDrop = false;

      // fire an event
      // @since 5.3.3
      de.fireEvent('dragend', {
        x,
        y,
        shiftKey,
        ctrlKey,
      });

      // check if we drop the port onto a valid
      // drop target and create a connection if possible
      //
      let brickConnection: any;
      if (this.currentDropTarget !== null) {
        this.mouseDraggingElement.onDrop(this.currentDropTarget, x, y, shiftKey, ctrlKey);

        this.currentDropTarget.onDragLeave(this.mouseDraggingElement);
        this.currentDropTarget.fireEvent('dragLeave', {
          draggingElement: this.mouseDraggingElement,
        });

        // Ports accepts only Ports as DropTarget
        //
        if (this.currentDropTarget instanceof draw2d.Port) {
          const request: any = new draw2d.command.CommandType(draw2d.command.CommandType.CONNECT);

          request.source = this.currentDropTarget;
          request.target = this.mouseDraggingElement;
          const command: any = this.mouseDraggingElement.createCommand(request);

          if (command !== null) {
            brickConnection = this.createConnection();
            const foundConnection = this.mouseDraggingElement.connections.data.findIndex(
              (c: any) => c.sourcePort.parent.id === request.target.parent.id
                && c.targetPort.parent.id === request.source.parent.id
                && c.targetPort.name === request.source.name
                && c.sourcePort.name === request.target.name,
            );
            if (foundConnection < 0) {
              brickConnection.setDeleteable(false);

              command.setConnection(brickConnection);

              brickConnection.onMouseEnter = () => {
                brickConnection.removeCssClass('brick-connection');
                brickConnection.addCssClass('brick-connection-fat');
              };

              brickConnection.onMouseLeave = () => {
                brickConnection.removeCssClass('brick-connection-fat');
                brickConnection.addCssClass('brick-connection');
              };

              brickConnection.onClick = () => {
                canvas.fireEvent('onClickConn', { brickConnection });
              };

              canvas.getCommandStack().execute(command);
              this.currentDropTarget.onCatch(this.mouseDraggingElement, x, y, shiftKey, ctrlKey);
            }
          }
        }
      }

      // end command stack trans
      canvas.getCommandStack().commitTransaction();

      if (brickConnection) {
        // Select connection in the canvas
        canvas.addSelection(brickConnection);

        // Open dialog and create connection
        canvas.fireEvent('connectionAdded', { brickConnection });
      }

      this.currentDropTarget = null;
      this.mouseDraggingElement = null;
    }
  },

  createConnection() {
    log('createConnection is called');

    const ConnectionRouterStyle = getConnectionRouterStyle();
    return new draw2d.Connection({
      router: new ConnectionRouterStyle(),
      color: 'rbg(0,0,0)',
    });
  },
});

export const CopyInterceptorPolicy: any = draw2d.policy.canvas.SingleSelectionPolicy.extend({
  NAME: 'CopyInterceptorPolicy',

  init() {
    // eslint-disable-next-line no-underscore-dangle
    this._super();

    this.cloneOnDrag = false;
  },

  /**
   * @method
   *
   * @param {draw2d.Canvas} canvas
   * @param {Number} x the x-coordinate of the mouse down event
   * @param {Number} y the y-coordinate of the mouse down event
   * @param {Boolean} shiftKey true if the shift key has been pressed during this event
   * @param {Boolean} ctrlKey true if the ctrl key has been pressed during the event
   */
  onMouseDown(canvas: any, x: number, y: number, shiftKey: any, ctrlKey: any) {
    this.cloneOnDrag = shiftKey;

    // eslint-disable-next-line no-underscore-dangle
    this._super(canvas, x, y, shiftKey, ctrlKey);
  },

  /**
   * Copy the selected figure if the user start dragging the selection.
   *
   */
  onMouseDrag(canvas: any,
    dx: number,
    dy: number,
    dx2: number,
    dy2: number,
    shiftKey: any,
    ctrlKey: any) {
    if (
      !(
        this.mouseDraggingElement instanceof draw2d.ResizeHandle
        || this.mouseDraggingElement instanceof draw2d.Port
      )
    ) {
      if (this.cloneOnDrag === true && this.mouseDraggingElement !== null) {
        // get the current position of the selected shape
        const pos = this.mouseDraggingElement.getPosition();

        // cancel the current drag&drop operation
        this.mouseDraggingElement.onDragEnd(pos.x, pos.y, false, false);

        // get cloned object of that dragged brick
        const newlyAddedBrick = this.canvas.onDrop('', pos.x, pos.y, '', '', true, this.mouseDraggingElement);
        this.mouseDraggingElement = newlyAddedBrick;

        // add the clone to the canvas and start dragging of the clone
        canvas.add(this.mouseDraggingElement, pos);

        // select the cloned shape
        this.select(canvas, this.mouseDraggingElement);

        // start dragging if the clone accept this operation
        this.mouseDraggingElement.onDragStart(pos.x, pos.y, false, false);
      }
    }

    this.cloneOnDrag = false;

    // eslint-disable-next-line no-underscore-dangle
    this._super(canvas, dx, dy, dx2, dy2, shiftKey, ctrlKey);
  },
});
