
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import * as models from '@/store/bricks/models';
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import { getVersionFromUrn } from '@/scripts/brickVersion';

const namespace: string = 'brick';

@Component
export default class BrickPane extends Vue {
  @Getter(types.GET_BRICK_NAMES, { namespace }) getSelectedBricks: any;

  @Getter(types.GET_PACKAGES, { namespace })
  getPackages: any;

  @Action(types.FILTER_BRICKS, { namespace }) filterBricks: any;

  @Getter(types.GET_BRICK_TYPES, { namespace }) getBrickTypes: any;

  @Action(types.SEARCH_BRICK, { namespace }) searchBrick: any;

  @Prop() selectedBrick!: models.InstalledBrick;

  private selectedFilter: string = 'filter_by_type';

  private changeFilter(filter: string) {
    if (!filter) {
      return;
    }
    this.selectedFilter = filter;
    this.filterBricks({
      searchParam: this.name,
      filterParam: filter,
    });
  }

  private resetTypeFilter() {
    this.selectedFilter = 'filter_by_type';
    this.filter = '';
    this.filterBricks({
      searchParam: this.name,
      filterParam: '',
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private getBrickIcon(brickFamily: string, brickimage: string = '') {
    return getBrickIcon(brickFamily, brickimage);
  }

  private name: string = '';

  @Watch('name')
  onSearchChange(value: string, oldValue: string) {
    const searchObj = {
      searchParam: this.name,
      filterParam: this.filter,
    };
    this.searchBrick(searchObj);
  }

  private filter: string = '';

  @Watch('filter')
  onFilterChange(value: string, oldValue: string) {
    this.changeFilter(value);
  }

  // eslint-disable-next-line class-methods-use-this
  updated() {
    this.$emit('brick-pane-updated');
  }

  private getBricksByPackage(brickPackage: any) {
    const bricks = this.getSelectedBricks;
    return bricks.filter((brick: any) => brick.packageId === brickPackage.id);
  }

  getVersion = (urn: string) => getVersionFromUrn(urn);
}
