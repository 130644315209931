
import { Component, Vue, Watch } from 'vue-property-decorator';
import draw2d from 'draw2d';
import { Action, Getter, Mutation } from 'vuex-class';
import * as $ from 'jquery';
import * as types from '@/store/types';
import { BrickInFlow, Connection } from '@/store/flow/models';
import FlowConverter from '@/scripts/editor/flowConverter';
import Loading from '@/components/shared/Loading.vue';
import Toolbar from '@/components/editor/ToolbarEditor.vue';
import BrickPane from '@/components/editor/BrickPane.vue';
import LogPane from '@/components/editor/LogPane.vue';
import FlowCanvas from '@/scripts/editor/FlowCanvas';
import PropertyPane from '@/components/editor/propertyPane/PropertyPane.vue';
import * as EditTypes from '@/scripts/editor/types';
import { logging } from '@/scripts/debugger';
import { sortBrickParameters } from '@/scripts/sort/sortProperty';
import BrickDialog from '@/components/bricks/BrickDialog.vue';
import { validateBricksParameters } from '@/scripts/editor/helper';
import ParameterErrors from '@/components/editor/ParameterErrors.vue';
import MappingDialogue from '@/components/editor/portMapping/MappingDialogue.vue';
import { disableFlowEditing } from '@/scripts/shared';
import {
  getConnectionRouterStyle,
  gridPolicy,
  CopyInterceptorPolicy,
} from '@/scripts/editor/canvasPolicy';
import { emptyWorkspace, Workspace } from '@/store/workspace/models';
import FailedMessage from '@/components/shared/FailedMessage.vue';
import { makeTreeviewItem } from '@/scripts/bricks/addPorts';
import { namespaces } from '@/scripts/namespaces';
import { InstalledBrick, Port } from '../../store/bricks/models';

const namespace: string = 'flow';
const namespaceBricks: string = 'brick';
const namespaceUser: string = 'user';
const namespaceWorkspace: string = 'workspace';

const lgAndUp = 'lgAndUp';
const mdAndDown = 'mdAndDown';

type Breakpoints = typeof lgAndUp | typeof mdAndDown;

@Component({
  components: {
    BrickPane,
    Loading,
    Toolbar,
    PropertyPane,
    LogPane,
    BrickDialog,
    ParameterErrors,
    MappingDialogue,
    FailedMessage,
  },
})
export default class EditDraw2d extends Vue {
  // Actions
  @Action(types.FETCH_FLOW, { namespace }) fetchFlow: any;

  @Action(types.SAVE_FLOW, { namespace }) updateFlow: any;

  @Action(types.FETCH_BRICKS, { namespace }) fetchBricks: any;

  @Action(types.CLEAR_FLOW_STATE, { namespace }) clearFlowState: any;

  @Action(types.CREATE_CONNECTION, { namespace: 'flow' }) createConnection: any;

  @Action(types.ADD_BRICK_IN_FLOW, { namespace }) addBrickInFlowState: any;

  @Action(types.FETCH_CONNECTIONS, { namespace }) fetchConnections: any;

  @Action(types.UPDATE_BRICK, { namespace }) updateBrick: any;

  @Action(types.FETCH_BRICKS, { namespace: namespaceBricks })
  fetchAvailableBricks: any;

  @Action(types.FETCH_PACKAGES, { namespace: namespaceBricks })
  fetchPackages: any;

  @Action(types.CHANGE_RUN_STATE, { namespace }) changeRunState: any;

  @Action(types.DELETE_BRICK, { namespace }) deleteBrickFromFlow: any;

  @Action(types.DELETE_CONNECTION, { namespace }) deleteConnection: any;

  // Mutations
  @Mutation(types.MUTATE_DELETE_CONNECTION, { namespace: namespaces.FLOW })
  deleteConnectionFromState: any;

  // Getter
  @Getter(types.GET_LOADING, { namespace: namespaceBricks })
  getLoadingBricks: any;

  @Getter(types.GET_AVAILABLE_BRICKS, { namespace: namespaceBricks })
  getAvailableBricks: any;

  @Getter(types.GET_FLOW, { namespace }) getFlow: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  @Getter(types.GET_BRICKS, { namespace }) getBricks: any;

  @Getter(types.GET_CONNECTIONS, { namespace }) getConnections: any;

  @Getter(types.GET_LOADING, { namespace }) getLoading: any;

  @Getter(types.GET_RUN_STATE, { namespace }) getRunState: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: namespaceWorkspace })
  getWorkspaces: any;

  private canvasId: string = 'canvas';

  private flowname: string = 'IBAK raritan';

  private flowID: string = '';

  private flowOwner: string = '';

  private CANVAS_WIDTH = 5000;

  private CANVAS_HEIGHT = 5000;

  public canvas: any = null;

  public isPort: boolean = false;

  public pos: any;

  public canvasSelection: string = '';

  public brickSelection: boolean = false;

  public nonSelectedNames: string[] = [];

  public logs: boolean = false;

  public selectedBrick: InstalledBrick = {
    id: '',
    name: '',
    family: '',
    parameters: [],
    description: '',
    package: '',
    autoscale_queue_level: 0,
    autoscale_max_instances: 0,
    exit_after_idle_seconds: 0,
    inputPorts: [],
    outputPorts: [],
    brickimage: '',
    flowId: '',
  };

  public userData: EditTypes.BrickData = {
    family: '',
    name: '',
    newName: '',
    type: '',
    id: '',
    urn: '',
    instanceId: '',
    parameters: [],
    package: '',
    autoscale_queue_level: 25,
    exit_after_idle_seconds: 10,
    autoscale_max_instances: 1,
    inputPorts: [],
    outputPorts: [],
    brickimage: '',
    description: '',
    flowId: this.flowID,
    owner: this.flowOwner,
  };

  public portData: any = {};

  private flowConverter: any;

  private mappingDialog: boolean = false;

  private targetPort: any = {};

  private sourcePort: any = {};

  private targetBrick: any = {};

  private sourceBrick: any = {};

  private isDragged = false;

  private FigurePolicy: any;

  private DragDropEditPolicy: any;

  private dialogParameterError: boolean = false;

  private invalidBrickParams: string[] = [];

  private disconnectedSource: any = null;

  private disconnectedTarget: any = null;

  private connectedSource: any = null;

  private connectedTarget: any = null;

  private isConnectionAnalysing = false;

  private brickFailed: string = '';

  private workspaceUnavailable: string = '';

  private failedToFetchFlow: string = '';

  workspaceId: string = '';

  middlePaneSize: number = 90;

  /**
  * Possible minimum sizes for the left/right pane in the editor.
  * Values are based on breakpoint of a screen.
  * The numbers are pixels used by the Splitpane library
  */
  minimumPaneSizes: Record<Breakpoints, number> = {
    lgAndUp: 10,
    mdAndDown: 20,
  };

  /**
  * Possible maximum sizes for the left/right pane in the editor.
  * Values are based on breakpoint of a screen.
  * The numbers are pixels used by the Splitpane library
  */
  maximumPaneSizes: Record<Breakpoints, number> = {
    lgAndUp: 20,
    mdAndDown: 40,
  };

  // eslint-disable-next-line class-methods-use-this
  private log(message: string) {
    logging('EditDraw2d.vue', message);
  }

  get workspace(): Workspace {
    const { workspaceId } = this.$route.params;
    if (this.getWorkspaces) {
      return this.getWorkspaces.find((w: Workspace) => w.id === workspaceId);
    }
    return emptyWorkspace;
  }

  @Watch('workspace')
  onWorkspacesChange() {
    this.init();
  }

  async init() {
    this.flowID = this.$route.params.flowId;
    this.workspaceUnavailable = '';
    if (!this.workspace) {
      this.workspaceUnavailable = 'Workspace is no available or Connection '
        + 'to the Flow Manager could not be established.';
      return;
    }

    try {
      await this.fetchFlow({ id: this.flowID });
    } catch (e) {
      this.failedToFetchFlow = (e as Error).message;
    }

    await this.fetchBricks();

    await this.fetchAvailableBricks(this.workspace.owner.id).catch((e: any) => {
      this.brickFailed = e.message;
    });

    await this.fetchPackages(this.workspace.owner.id).catch((e: any) => {
      if (e.message === 'Network Error') {
        this.failedToFetchFlow = 'Connection to the Package Manager could not be established.';
      } else {
        this.failedToFetchFlow = e.message;
      }
    });

    this.flowname = this.getFlow.name;
    this.flowOwner = this.getFlow.owner;
    const connections = this.getConnections;
    this.flowConverter = new FlowConverter(this.getFlow, connections);

    this.createCanvas();

    this.loadFlow();

    this.toggleCanvasEdit();
  }

  async mounted() {
    this.log('mounted is called');
    this.workspaceId = this.$route.params.workspaceId;
    await this.init();

    // remove body's scroll bar
    document.body.style.overflowY = 'hidden';
  }

  // eslint-disable-next-line class-methods-use-this
  beforeDestroy() {
    this.clearFlowState();

    document.body.style.overflowY = 'auto';
  }

  @Watch('getRunState')
  private onGetRunStateChanged(value: boolean) {
    this.log('Watch("getRunState") is called');
    if (this.canvas) {
      this.toggleCanvasEdit();

      // if something is selected and flow has started
      if (this.isCanvasObjectSelected && value) {
        this.middlePaneSize = 90;
      } else if (!this.isCanvasObjectSelected && value) {
        // nothing is selected and flow has started
        this.middlePaneSize = 100;
      }
    }
  }

  /**
   * Selects the brick from brickpane
   * and shows it instead of the property
   * pane. If the property pane is open
   * it will be closed
   */
  private selectBrick(brick: InstalledBrick) {
    this.log(`selectBrick is called , ${brick}`);
    this.selectedBrick = brick;

    this.canvasSelection = '';
    this.brickSelection = true;
  }

  /**
  * Can be used to show if something on the Flow
  * is selected for e.g. a brick or a port
  */
  get isCanvasObjectSelected(): boolean {
    return this.brickSelection || !!this.canvasSelection;
  }

  /**
   * Get conditions when flow editing needs to be
   * disabled:
   */
  get disableFlowEditing() {
    this.log('disableFlowEditing is called');
    return disableFlowEditing(this.getFlow);
  }

  // pane sizes for the brick pane
  get minLeftPaneSize(): number {
    if (this.disableFlowEditing) return 0;

    return this.$vuetify.breakpoint.lgAndUp
      ? this.minimumPaneSizes[lgAndUp]
      : this.minimumPaneSizes[mdAndDown];
  }

  get maxLeftPaneSize(): number {
    if (this.disableFlowEditing) return 0;

    return this.$vuetify.breakpoint.lgAndUp
      ? this.maximumPaneSizes[lgAndUp]
      : this.maximumPaneSizes[mdAndDown];
  }

  // pane sizes for the property pane/brick info dialog
  get minRightPaneSize(): number {
    if (
      // if something is selected and flow is running
      (this.isCanvasObjectSelected && this.disableFlowEditing)
      // if something is selected and flow is not running
      || (this.isCanvasObjectSelected && !this.disableFlowEditing)
    ) {
      return this.$vuetify.breakpoint.lgAndUp
        ? this.minimumPaneSizes[lgAndUp]
        : this.minimumPaneSizes[mdAndDown];
    }

    if (
      // if nothing is selected and flow is running
      (!this.isCanvasObjectSelected && this.disableFlowEditing)
      // OR nothing is selected and flow is not running
      || (!this.isCanvasObjectSelected && !this.disableFlowEditing)
    ) {
      return 0;
    }

    return 0;
  }

  get maxRightPaneSize(): number {
    if (
      // if something is selected and flow is running
      (this.isCanvasObjectSelected && this.disableFlowEditing)
      // if something is selected and flow is not running
      || (this.isCanvasObjectSelected && !this.disableFlowEditing)
    ) {
      return this.$vuetify.breakpoint.lgAndUp
        ? this.maximumPaneSizes[lgAndUp]
        : this.maximumPaneSizes[mdAndDown];
    }

    // if nothing is selected and flow is running
    if (!this.isCanvasObjectSelected && this.disableFlowEditing) {
      // reset middle pane size to 100 because splitpane leaves an empty space otherwise
      if (this.$refs.middlePane) (this.$refs.middlePane as HTMLElement).style.width = '100%';

      return 0;
    }

    return 0;
  }

  /**
   * Sets the readOnly flag for the
   * canvas instance AND Toggles the selectability
   * of connection lines between the figures
   */
  toggleCanvasEdit() {
    this.log('toggleCanvasEdit is called');
    const isFlowRunning = this.getRunState;
    this.canvas.setReadOnly = this.disableFlowEditing;

    this.canvas.getAllPorts().each((index: number, port: any) => {
      port.getConnections().each((_: any, connection: any) => {
        connection.setSelectable(!isFlowRunning);
      });
    });
  }

  async createNewConnection(sourceBrick: any, targetBrick: any, sourcePort: any, targetPort: any) {
    this.log('createNewConnection is called');

    const sourceData = this.getUserdata(sourceBrick.name, sourceBrick);
    this.$set(sourceBrick, 'instanceId', sourceData.instanceId);
    const targetData = this.getUserdata(targetBrick.name, targetBrick);
    this.$set(targetBrick, 'instanceId', targetData.instanceId);

    if (sourceBrick.instanceId === undefined || targetBrick.instanceId === undefined) {
      return;
    }
    const newConnection: Connection = {
      id: '',
      source: {
        brick: sourceBrick.instanceId,
        port: {
          id: sourcePort.name,
          typeName: sourcePort.typeName,
          schema: sourcePort.schema,
        },
      },
      target: {
        brick: targetBrick.instanceId,
        port: {
          id: targetPort.name,
          typeName: targetPort.typeName,
          schema: targetPort.schema,
        },
      },
      mapping: [],
      buffer: {},
      workspaceId: this.workspaceId,
    };

    await this.createConnection({
      configID: this.getFlow.config.connectionConfigId,
      connection: newConnection,
    });
  }

  createCanvas() {
    this.log(`createCanvas is called for canvas ID ', ${this.canvasId}`);
    const canvas = new FlowCanvas(this.canvasId, this.CANVAS_WIDTH, this.CANVAS_HEIGHT);
    const self = this;

    // Pack in "this" inside a variable for the policy to access
    const selection = this.onSelection(canvas, this);
    const onDeletePressed = this.onkeyDelete(canvas, this);

    // Create canvas policy to register click/touch events
    const CanvasPolicy = draw2d.policy.canvas.CanvasPolicy.extend({
      NAME: 'CanvasPolicy',

      // Turn off property pane
      onMouseUp(clickedObject: any) {
        if (!clickedObject.selection.primary) {
          selection.select();
        }
      },
    });
    // Create canvas policy to let Vue know when a brick was deleted
    const KeyboardPolicy = draw2d.policy.canvas.DefaultKeyboardPolicy.extend({
      NAME: 'DefaultKeyboardPolicy',

      onKeyDown(_: any, keyCode: number) {
        if (keyCode === 46 && self.canvas.selection.all.data.length > 0) {
          if (!self.disableFlowEditing) {
            onDeletePressed();
          }
        }
      },
    });

    // Install policies
    canvas.installEditPolicy(new CanvasPolicy());
    canvas.installEditPolicy(new KeyboardPolicy());
    canvas.installEditPolicy(gridPolicy);

    // Define figure policy to use
    this.FigurePolicy = draw2d.policy.figure.AntSelectionFeedbackPolicy.extend({
      NAME: 'FigurePolicy',

      // Toggle the isDragged boolean to avoid
      // opening property pane if the figure was
      // dragged and NOT clicked
      onDrag() {
        self.isDragged = true;
      },

      // Blocks the drag for figures when
      // flow is running
      onDragStart() {
        return !self.getRunState;
      },

      async onDragEnd(_: any, figure: any) {
        const brick = figure;

        if (self.isDragged) {
          brick.userData = self.getUserdata(brick.id, brick.userData);

          // extract location from canvas
          brick.userData.location = self.flowConverter.setBrickLocation(brick, self.canvas);

          // form the brick object before saving
          const updatedBrick = self.flowConverter.getBrick(brick.userData);

          // update the brick with new location
          await self.updateBrick({ brick: updatedBrick, isShowSuccess: false });
        }
      },
    });

    // Register figure click when new brick is added
    canvas.on('brickAdded', async (emitter: any, event: any) => {
      const { brick } = event;

      // extract location from canvas
      brick.userData.location = self.flowConverter.setBrickLocation(event.brick, self.canvas);

      // form the brick object before saving
      const newBrick = self.flowConverter.getBrick(brick.userData);

      // Add brick to the flow state
      try {
        await self.addBrickInFlowState(newBrick);

        brick.installEditPolicy(new self.FigurePolicy());

        // save flow to save brick
        if (self.flowConverter.flow.id !== '') {
          // this step is important for location of bricks
          self.saveSettings();
        }

        // Select a figure to show up on the property pane
        brick.on('click', (e: any) => {
          if (!self.isDragged) {
            e.canvas.addSelection(e);
            selection.select();
          }

          self.isDragged = false;
        });
      } catch (err) {
        self.canvas.addSelection(brick);
        this.deleteElement();
        console.error('failed to add the brick, got error: ', (err as Error).message);
      }
    });

    canvas.on('connectionAdded', async (emitter: any, event: any) => {
      const { sourcePort, targetPort } = event.brickConnection;

      self.initializeMappingDialogValues(sourcePort, targetPort, self);

      if (sourcePort !== undefined) {
        await this.createNewConnection(
          self.sourceBrick,
          self.targetBrick,
          self.sourcePort,
          self.targetPort,
        );

        // open dialog
        self.mappingDialog = !self.mappingDialog;
      }
    });

    // Show port information when clicked
    canvas.on('onClickPort', (emitter: any, event: any) => {
      const { newport } = event;
      const { portData } = newport.userData;

      self.isPort = true;
      self.canvasSelection = newport.userData.brickData.newName
        ? newport.userData.brickData.newName
        : newport.userData.brickData.name;

      self.pos = [100, 100];

      self.userData = this.getUserdata(this.canvasSelection, this.userData);

      let port: Port | undefined;

      if (portData.type === 'input') {
        port = self.userData.inputPorts.find((p) => p.name === portData.name);
      } else {
        port = self.userData.outputPorts.find((p) => p.name === portData.name);
      }

      if (port) {
        self.portData = portData;
        self.portData.populatedJSON = port.populatedJSON;

        self.portData.typeDef = makeTreeviewItem(port.populatedJSON);
      }

      self.nonSelectedNames = [];
      emitter.figures.data.forEach((figure: any) => {
        if (self.canvasSelection !== figure.id) {
          self.nonSelectedNames.push(figure.id);
        }
      });

      self.brickSelection = false;
    });

    canvas.on('onClickConn', (emitter: any, event: any) => {
      const { sourcePort, targetPort } = event.brickConnection;

      self.initializeMappingDialogValues(sourcePort, targetPort, self);

      self.mappingDialog = !self.mappingDialog;
    });

    canvas.on('onDisconnectPort', async (emitter: any, event: any) => {
      const {
        connection: { connection },
      } = event;

      if (connection.targetPort && connection.sourcePort) {
        const { sourcePort, targetPort } = connection;

        if (!self.disconnectedSource && !self.disconnectedTarget) {
          self.disconnectedSource = sourcePort;
          self.disconnectedTarget = targetPort;
        }
      }
    });

    canvas.on('onConnectPort', async (emitter: any, event: any) => {
      const {
        connection: { connection },
      } = event;

      if (connection.targetPort && connection.sourcePort) {
        const { sourcePort, targetPort } = connection;

        if (!self.connectedSource && !self.connectedTarget) {
          // extract brick and port data of the source and target
          const {
            userData: { brickData: sourceBrick, portData: srcPort },
          } = sourcePort;

          const {
            userData: { brickData: targetBrick, portData: trgtPort },
          } = targetPort;

          // find the connection that was just connected
          const find: Connection = this.getConnections.find(
            (c: Connection) => c.source.brick === sourceBrick.instanceId
              && c.target.brick === targetBrick.instanceId
              && c.source.port.id === srcPort.name
              && c.target.port.id === trgtPort.name,
          );

          // if connection does not exist, it was newly connected
          // therefore we set the variables
          if (!find) {
            self.connectedSource = sourcePort;
            self.connectedTarget = targetPort;
          } else if (self.disconnectedSource && self.disconnectedTarget) {
            const {
              userData: { brickData: disconnectedSrcBrick, portData: disconnectedSrcPort },
            } = self.disconnectedSource;

            const {
              userData: { brickData: disconnectedTrgtBrick, portData: disconnectedTrgtPort },
            } = self.disconnectedTarget;

            // if the connection was dropped back to its own
            // original port, we do nothing and we unset all
            // variables to avoid any deletions or creations
            // of extra connections
            if (
              disconnectedSrcBrick.instanceId === sourceBrick.instanceId
              && disconnectedSrcPort.name === srcPort.name
              && disconnectedTrgtBrick.instanceId === targetBrick.instanceId
              && disconnectedTrgtPort.name === trgtPort.name
            ) {
              self.connectedSource = null;
              self.connectedTarget = null;
              self.disconnectedSource = null;
              self.disconnectedTarget = null;
            }
          }
        }

        // analyse connected and disconnected connections
        await self.analyseConnections();
      }
    });
    this.canvas = canvas;
  }

  // eslint-disable-next-line class-methods-use-this
  onkeyDelete(emitter: any, node: any) {
    // eslint-disable-next-line func-names
    return function () {
      node.log('deleteElement is called from delete key');
      node.deleteBrick();
    };
  }

  // eslint-disable-next-line class-methods-use-this
  onSelection(emitter: any, node: any) {
    this.log('onSelection is called');

    return {
      select() {
        if (
          emitter.selection.primary
          && emitter.selection.primary.cssClass === 'draw2d_SetFigure'
        ) {
          // eslint-disable-next-line no-param-reassign
          node.userData = node.getUserdata(
            emitter.selection.primary.id,
            emitter.selection.primary.userData,
          );

          // eslint-disable-next-line no-param-reassign
          node.canvasSelection = emitter.selection.primary.id;

          // eslint-disable-next-line no-param-reassign
          node.pos = [emitter.selection.primary.x, emitter.selection.primary.y];
          // eslint-disable-next-line no-param-reassign
          node.isPort = false;
          // eslint-disable-next-line no-param-reassign
          node.nonSelectedNames = [];
          emitter.figures.data.forEach((figure: any) => {
            if (node.canvasSelection !== figure.id) {
              node.nonSelectedNames.push(figure.id);
            }
          });
          // eslint-disable-next-line no-param-reassign
          node.brickSelection = false;
        } else {
          node.closePropertyPane();
          // eslint-disable-next-line no-param-reassign
          node.nonSelectedNames = [];
        }
      },
    };
  }

  private getUserdata(name: string, userdata: EditTypes.BrickData) {
    this.log(`getUserdata is called and get arg, ${userdata}`);
    const updatedData: EditTypes.BrickData = userdata;
    //  no parameters
    if (userdata.parameters === null) {
      updatedData.parameters = [];
      return updatedData;
    }

    // brick already saved in flow
    const flow = this.getFlow;
    const { bricks } = flow.config;
    if (bricks !== null) {
      const knownBrick: BrickInFlow = bricks.find((b: BrickInFlow) => b.name === name);

      if (knownBrick !== undefined) {
        updatedData.parameters = sortBrickParameters(knownBrick, this.getAvailableBricks);
        updatedData.autoscale_queue_level = knownBrick.autoscale_queue_level;
        updatedData.autoscale_max_instances = knownBrick.autoscale_max_instances;
        updatedData.exit_after_idle_seconds = knownBrick.exit_after_idle_seconds;
        updatedData.instanceId = knownBrick.instanceId;
        updatedData.location = knownBrick.location;
        updatedData.inputPorts = knownBrick.inputPorts;
        updatedData.outputPorts = knownBrick.outputPorts;
        return updatedData;
      }
    }
    return updatedData;
  }

  private loadFlow() {
    this.log('loadFlow is called');

    // Draw flow with figures
    if (this.getBricks) {
      this.flowConverter.drawFlow(
        this.getAvailableBricks,
        this.getBricks,
        this.getConnections,
        this.canvas,
      );
    }

    // Pack in "this" inside a variable for the policy to access
    const selection = this.onSelection(this.canvas, this);

    const self = this;

    this.canvas.installEditPolicy(new CopyInterceptorPolicy());

    this.canvas.getFigures().each((_: any, figure: any) => {
      figure.installEditPolicy(new self.FigurePolicy());

      // Select a figure to show up on the property pane
      figure.on('click', (event: any) => {
        if (!self.isDragged) {
          event.canvas.addSelection(event);
          selection.select();
        }

        self.isDragged = false;
      });
    });
  }

  /**
   * Updates the @member flowConverter object
   * with the latest changes from the state
   * and the canvas to keep it up to date
   */
  saveSettings() {
    this.log(`saveSettings is called to save flow', ${this.getFlow}`);

    this.flowConverter.flow = this.getFlow;
    this.flowConverter.bricks = this.getFlow.config.bricks;
    this.flowConverter.update(this.canvas);
  }

  /**
   * Closes the parameter error dialog
   * and sets the list of errors that were
   * displayed to an empty array
   */
  closeParameterError() {
    this.dialogParameterError = false;
    this.invalidBrickParams = [];
  }

  /**
   * - Validates the flow by checking the parameters
   * in the bricks and informs the user of the parameters
   * that need attention
   * - Generates a PNG image of the flow
   * - Updates @member userData to update the property pane
   * if opened
   * - Hides the secret parameters
   */
  async saveFlow() {
    this.log(`saveFlow is called to save flow', ${this.getFlow}`);

    const { toolbar }: any = this.$refs;

    toolbar.loading = true;

    if (!this.getRunState) {
      // Validate the requires brick parameters before save
      if (this.getFlow && this.getFlow.config && this.getFlow.config.bricks) {
        const { bricks } = this.getFlow.config;

        const invalidProperties = validateBricksParameters(bricks);

        if (invalidProperties && invalidProperties.length > 0) {
          this.dialogParameterError = true;
          this.invalidBrickParams = invalidProperties;

          toolbar.loading = false;

          return;
        }
      }

      const pngBase64 = await this.createPreviewImageFromCanvas();
      this.flowConverter.flow.previewimage = pngBase64;

      // update the userdata if property pane is open
      if (this.canvasSelection) {
        this.userData = this.getUserdata(this.canvasSelection, this.userData);
      }

      // hide secrets automatically
      const { propertyPane }: any = this.$refs;
      if (propertyPane) propertyPane.show = false;
    }

    await this.changeRunState({
      id: this.$route.params.flowId,
      running: !this.getRunState,
    });

    toolbar.loading = false;
  }

  /**
   * Scans the flow and creates a PNG-Image of
   * the flow with all the bricks which can be
   * then seen in the flow info dialog
   *
   * @returns {string} - a BASE64 image of the
   * flow with its bricks
   */
  private createPreviewImageFromCanvas() {
    this.log('createPreviewImageFromCanvas is called');
    const writer = new draw2d.io.png.Writer();

    // Set Parameter for bounding Rectanangle for Preview Image
    const boundingBox = new draw2d.geo.Rectangle(0, 0, this.CANVAS_WIDTH, this.CANVAS_HEIGHT);

    // Get a cropped image data
    const imageData = new Promise((resolve, reject) => {
      writer.marshal(this.canvas, (pngString: any) => {
        resolve(pngString);
      });
    });

    return imageData;
  }

  private deleteElement() {
    this.log('deleteElement is called');

    for (const selected of this.canvas.selection.all.data) {
      const cmd = new draw2d.command.CommandDelete(selected);
      this.canvas.getCommandStack().execute(cmd);
    }
  }

  /**
   * Turns OFF or ON the gridlines in the canvas
   */
  private toggleGridLines(shouldShowGrids: boolean) {
    if (shouldShowGrids) {
      this.canvas.installEditPolicy(gridPolicy);
    } else {
      this.canvas.uninstallEditPolicy(gridPolicy);
    }
  }

  private zoomIn() {
    this.log('zoomIn is called');
    this.canvas.setZoom(this.canvas.getZoom() * 0.7, true);
  }

  private zoomOut() {
    this.log('zoomIn out called');
    this.canvas.setZoom(this.canvas.getZoom() * 1.3, true);
  }

  private resetZoom() {
    this.log('resetZoom is called');
    this.canvas.setZoom(1.0, true);
  }

  private redo() {
    // change the isDirty state to true
    this.$store.dispatch('flow/SET_FLOW_IS_DIRTY', true, { root: true });

    this.log('redo is called');
    this.canvas.getCommandStack().redo();
  }

  private undo() {
    // change the isDirty state to true
    this.$store.dispatch('flow/SET_FLOW_IS_DIRTY', true, { root: true });

    this.log('undo is called');
    this.canvas.getCommandStack().undo();
  }

  private closePropertyPane() {
    this.log('closePropertyPane is called');
    this.canvasSelection = '';
    this.brickSelection = false;
  }

  private changeConnectionStyle() {
    if (this.canvas === undefined) {
      return;
    }
    // Toggle connection style for all existing connections
    const ConnectionRouterStyle = getConnectionRouterStyle();
    this.canvas.getAllPorts().each((_: any, port: any) => {
      port.getConnections().each((__: any, connection: any) => {
        connection.setRouter(new ConnectionRouterStyle());
      });
    });
  }

  /**
   * When the DOM re-renders draw2D canvas
   * needs to be informed of the new elements
   * so that they are draggable and
   * droppable again
   */
  applyDraggableOnBricks() {
    this.log('hasProperty is called');

    if (!this.canvas) {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    const getCanvasEvent = this.canvas._getEvent;
    const html: any = $(`#${this.canvasId}`);
    const {
      canvas,
      canvas: {
        onDrag, onDrop, onDragEnter, onDragLeave, onConnect, onDisconnect,
      },
    } = this;

    /**
     * Values needed to run "fromDocumentToCanvasCoordinate"
     * function from draw2d manually
     */
    const { left, top } = html.offset();
    const scrollLeft = this.canvas.scrollArea.scrollLeft();
    const scrollTop = this.canvas.scrollArea.scrollTop();
    const zoomFactor = this.canvas.getZoom();

    html.droppable({
      accept: '.draw2d_droppable',
      over(event: any, ui: any) {
        onDragEnter(ui.draggable);
      },
      out(event: any, ui: any) {
        onDragLeave(ui.draggable);
      },
      drop(event: any, ui: any) {
        // eslint-disable-next-line no-param-reassign
        event = getCanvasEvent(event);

        const { clientX, clientY } = event;

        const pos = new draw2d.geo.Point(
          (clientX - left + scrollLeft) * zoomFactor,
          (clientY - top + scrollTop) * zoomFactor,
        );

        onDrop.apply(canvas, [ui.draggable, pos.getX(), pos.getY(), event.shiftKey, event.ctrlKey]);
      },
    });

    // Create the jQuery-Draggable for the palette -> canvas drag&drop interaction
    $('.draw2d_droppable').draggable({
      appendTo: 'body',
      stack: 'body',
      zIndex: 27000,
      helper: 'clone',
      drag(event: any, ui: any) {
        // eslint-disable-next-line no-param-reassign
        event = getCanvasEvent(event);
        const { clientX, clientY } = event;

        const pos = new draw2d.geo.Point(
          (clientX - left + scrollLeft) * zoomFactor,
          (clientY - top + scrollTop) * zoomFactor,
        );

        onDrag(ui.draggable, pos.getX(), pos.getY(), event.shiftKey, event.ctrlKey);
      },
      start(event: any, ui: any) {
        $(ui.helper).addClass('shadow');
      },
    });
  }

  /**
   * Closes the mapping dialog by calling the
   * mapping dialog's @function closeMappingDialog()
   * and letting the child component handle it
   */
  async closeMappingDialog() {
    const { mappingDialog }: any = this.$refs;

    await mappingDialog.closeMappingDialog();
  }

  /**
   * Update the userData from the store again
   * so the props are all refreshed with
   * the child components
   */
  updateUserData() {
    if (this.canvasSelection) {
      this.userData = this.getUserdata(this.canvasSelection, this.userData);
    }
  }

  /**
   * Deletes selected bricks on the canvas
   * along with the connections the bricks
   * have
   */
  async deleteBrick() {
    const { toolbar }: any = this.$refs;

    toolbar.deleteBrickLoading = true;

    // get all selections that are "BRICKS"
    // excluding connections
    const brickSelections = this.canvas.selection.all.data.filter(
      (selection: any) => selection.cssClass === 'draw2d_SetFigure',
    );

    // form promises for bricks
    const brickDeletionPromises = brickSelections.map(
      (selection: any) => new Promise((resolve, reject) => {
        this.closePropertyPane();

        // get updated user data incase instanceID is missing
        const updatedUserData = this.getUserdata(selection.id, selection.userData);
        this.$set(selection, 'userData', updatedUserData);

        try {
          // delete brick
          this.deleteBrickFromFlow({
            brickId: selection.userData.instanceId,
          });

          resolve(selection);
        } catch (error) {
          reject(error);
        }
      }),
    );

    // form promises for connections
    const connectionDeletionPromises = this.getConnections
      .map((connection: Connection) => {
        // get all brick selections as names
        const selectedBrickNames: string[] = brickSelections.map(
          (selection: any) => selection.userData.instanceId,
        );
        // only delete those connections whose
        // bricks are being deleted
        if (
          selectedBrickNames.includes(connection.source.brick)
          || selectedBrickNames.includes(connection.target.brick)
        ) {
          return new Promise((resolve, reject) => {
            try {
              // delete connection
              this.deleteConnection({
                configID: this.getFlow.config.connectionConfigId,
                connectionID: connection.id,
              });

              resolve(true);
            } catch (error) {
              reject(error);
            }
          });
        }

        return null;
      })
      .filter((p: any) => p); // filter out nulls

    // make a flat array of all promises
    const promises = [brickDeletionPromises, connectionDeletionPromises].flat();

    // execute all promises
    Promise.all(promises).then((selections) => {
      selections
        // filter results that are NOT bricks
        .filter((s) => typeof s !== 'boolean')
        // remove each brick from the canvas
        .forEach((selection) => {
          const cmd = new draw2d.command.CommandDelete(selection);
          this.canvas.getCommandStack().execute(cmd);
        });

      toolbar.deleteBrickLoading = false;
    });
  }

  async copyBrick() {
    const { toolbar }: any = this.$refs;

    toolbar.copyBrickLoading = true;

    // get all selections that are "BRICKS"
    // excluding connections
    const brickSelections = this.canvas.selection.all.data.filter(
      (selection: any) => selection.cssClass === 'draw2d_SetFigure',
    );

    brickSelections.map((brick: any) => {
      const x = brick.x + 20;
      const y = brick.y - 20;

      this.canvas.onDrop('', x, y, '', '', true, brick);

      return brick;
    });

    toolbar.copyBrickLoading = false;
  }

  /**
   * Prepares the variables needed by the MappingDialog
   *
   * @param sourcePort
   * @param targetPort
   * @param thisContext - Sometimes the function is run from different
   * context and needs the context where the variables exist
   */
  initializeMappingDialogValues(sourcePort: any, targetPort: any, thisContext = this) {
    const self = thisContext;

    if (!sourcePort.userData.brickData.instanceId) {
      const sourceData = this.getUserdata(
        sourcePort.userData.brickData.name,
        sourcePort.userData.brickData,
      );
      this.$set(sourcePort.userData.brickData, 'instanceId', sourceData.instanceId);
    }

    if (!targetPort.userData.brickData.instanceId) {
      const targetData = this.getUserdata(
        targetPort.userData.brickData.name,
        targetPort.userData.brickData,
      );
      this.$set(targetPort.userData.brickData, 'instanceId', targetData.instanceId);
    }

    // fetch fresh brick from state
    const sBrick: BrickInFlow = self.getFlow.config.bricks.find(
      (b: BrickInFlow) => b.instanceId === sourcePort.userData.brickData.instanceId,
    );
    const tBrick: BrickInFlow = self.getFlow.config.bricks.find(
      (b: BrickInFlow) => b.instanceId === targetPort.userData.brickData.instanceId,
    );

    // In some point the worspace id is disappear from the obejct, to fix the
    // problem we set the workspace id explicitly here
    tBrick.workspaceId = this.workspaceId;
    sBrick.workspaceId = this.workspaceId;

    // fetch fresh port from state
    const sPort = sBrick.outputPorts.find(
      (port) => port.name === sourcePort.userData.portData.name,
    );
    const tPort = tBrick.inputPorts.find((port) => port.name === targetPort.userData.portData.name);

    if (sourcePort !== undefined && sourcePort.userData.portData.type === 'output') {
      self.sourcePort = sPort;
      self.$set(self.sourcePort, 'type', 'output');
      self.sourceBrick = sBrick;

      self.targetPort = tPort;
      self.$set(self.targetPort, 'type', 'input');
      self.targetBrick = tBrick;
    } else {
      self.sourcePort = tPort;
      self.$set(self.sourcePort, 'type', 'output');
      self.sourceBrick = tBrick;

      self.targetPort = sPort;
      self.$set(self.targetPort, 'type', 'input');
      self.targetBrick = sBrick;
    }
  }

  /**
   * Goes through the tracked connections that
   * were disconnected or connected and either
   * deletes AND/OR creates new connections based
   * on the variables
   */
  async analyseConnections() {
    // if operation is already going on, do nothing
    if (this.isConnectionAnalysing) return;

    // all four variables are available that means we can
    // delete the disconnected connection and create the
    // new connected connection
    if (
      this.disconnectedSource
      && this.disconnectedTarget
      && this.connectedSource
      && this.connectedTarget
    ) {
      this.isConnectionAnalysing = true;

      const isConnectionDeleted = await this.deleteDisconnectedConnection();

      // only create new if the delete was successfull
      if (isConnectionDeleted) {
        const {
          userData: { brickData: sourceBrick, portData: sourcePort },
        } = this.connectedSource;

        const {
          userData: { brickData: targetBrick, portData: targetPort },
        } = this.connectedTarget;

        await this.createNewConnection(sourceBrick, targetBrick, sourcePort, targetPort);

        this.initializeMappingDialogValues(this.connectedSource, this.connectedTarget, this);

        // reset all variables to reset tracking
        this.connectedSource = null;
        this.connectedTarget = null;
        this.disconnectedSource = null;
        this.disconnectedTarget = null;

        this.isConnectionAnalysing = false;

        this.mappingDialog = !this.mappingDialog;
      }
    } else if (
      this.connectedSource
      && this.connectedTarget
      && !this.disconnectedSource
      && !this.disconnectedTarget
    ) {
      // if a connection was connected but nothing was disconnected
      // we do nothing as there's already a canvas operation (connectedAdded)
      // that takes care of this

      this.connectedSource = null;
      this.connectedTarget = null;
    } else if (
      !this.connectedSource
      && !this.connectedTarget
      && this.disconnectedSource
      && this.disconnectedTarget
    ) {
      // if a connection was dropped on a port combination that
      // already has a connection, we just delete the connection
      // from where it was moved, and not bother to create a new one

      this.isConnectionAnalysing = true;

      await this.deleteDisconnectedConnection();

      // delete the selected connection because a connection line is
      // already there, so we do not want double connections
      this.deleteElement();

      // reset variables to reset tracking
      this.disconnectedSource = null;
      this.disconnectedTarget = null;

      this.isConnectionAnalysing = false;
    }
  }

  /**
   * Deletes a connection that was disconnected by moving
   * it from one port to another
   *
   * @returns - TRUE if connection was deleted
   * @else FALSE
   */
  async deleteDisconnectedConnection(): Promise<Boolean> {
    let isConnectionDeleted = false;

    if (this.disconnectedSource && this.disconnectedTarget) {
      // extract brick and port data of the source
      const {
        userData: { brickData: sourceBrick, portData: sourcePort },
      } = this.disconnectedSource;

      // extract brick and port data of the target
      const {
        userData: { brickData: targetBrick, portData: targetPort },
      } = this.disconnectedTarget;

      // Find the connection that needs to be deleted
      const connection: Connection = this.getConnections.find(
        (c: Connection) => c.source.brick === sourceBrick.instanceId
          && c.target.brick === targetBrick.instanceId
          && c.source.port.id === sourcePort.name
          && c.target.port.id === targetPort.name,
      );

      if (connection) {
        try {
          // delete connection
          await this.deleteConnection({
            configID: this.getFlow.config.connectionConfigId,
            connectionID: connection.id,
          });

          isConnectionDeleted = true;
        } catch (error) {
          isConnectionDeleted = false;
        }
      }
    }

    return isConnectionDeleted;
  }

  /**
   * Deletes connections from the canvas i.e.
   * only removes them from the canvas and NOT
   * the backend
   *
   * @param connectionsToDelete - A list of connection
   * IDs to delete
   */
  deleteConnections(connectionsToDelete: string[]) {
    const self = this;

    this.canvas.getFigures().each((_: any, figure: any) => {
      figure.getPorts().each((__: any, port: any) => {
        port.getConnections().each((____: any, connection: any) => {
          const { sourcePort, targetPort } = connection;

          const sBrick: BrickInFlow = self.getFlow.config.bricks.find(
            (b: BrickInFlow) => b.id === sourcePort.userData.brickData.id,
          );
          const sPort = sBrick.outputPorts.find(
            (p) => p.name === sourcePort.userData.portData.name,
          );

          const tBrick: BrickInFlow = self.getFlow.config.bricks.find(
            (b: BrickInFlow) => b.id === targetPort.userData.brickData.id,
          );
          const tPort = tBrick.inputPorts.find((p) => p.name === targetPort.userData.portData.name);

          if (sPort && tPort) {
            const found: Connection = self.getConnections.find(
              (c: Connection) => c.source.brick === sBrick.instanceId
                && c.target.brick === tBrick.instanceId
                && c.source.port.id === sPort.name
                && c.target.port.id === tPort.name,
            );

            if (connectionsToDelete.includes(found.id)) {
              const cmd = new draw2d.command.CommandDelete(connection);
              self.canvas.getCommandStack().execute(cmd);

              self.deleteConnectionFromState(found.id);
            }
          }
        });
      });
    });
  }
}
