
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';
import * as EditTypes from '@/scripts/editor/types';
import { Parameter } from '@/store/bricks/models';
import { getBrickIcon } from '@/scripts/bricks/getIcon';
import Layout from '@/components/shared/Layout.vue';
import TextareaEditor from '@/components/editor/propertyPane/parameters/TextareaEditor.vue';
import StringListEditor from '@/components/editor/propertyPane/parameters/StringListEditor.vue';
import PortDescription from '@/components/editor/propertyPane/PortDescription.vue';
import EndpointAndAPI from '@/components/editor/propertyPane/parameters/EndpointAndAPI.vue';
import { logging } from '@/scripts/debugger';
import i18n from '@/middlewares/i18n';
import { validateParameter } from '@/scripts/editor/helper';
import { DecryptParameter } from '@/store/flow/apis';
import PropertyPaneHeader from '@/components/editor/propertyPane/PaneHeader.vue';
import { EDITOR_STRING_LIST, EDITOR_TEXT_AREA, SECRET_TYPE } from '@/scripts/shared';
import { CONFIGURE_APIBRICK, FETCH_APIS } from '@/store/types';
import { namespaces } from '@/scripts/namespaces';

const TypeErrorNumber: string = 'type_error_number';
const TypeErrorNotInt: string = 'type_error_int';

interface Property {
  name: string;
  value: number;
}

@Component({
  components: {
    Layout,
    TextareaEditor,
    StringListEditor,
    PropertyPaneHeader,
    PortDescription,
    EndpointAndAPI,
  },
})
export default class PropertyPane extends Vue {
  // Props
  @Prop() isPort!: boolean;

  @Prop() brickName!: string;

  @Prop() userdata!: EditTypes.BrickData;

  @Prop() portData: any;

  @Prop() flowID!: string;

  @Prop() flowOwner!: string;

  @Prop() remainingBricks!: string[];

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  // Actions
  @Action(types.SET_ERROR, { namespace: namespaces.ERROR }) setError: any;

  @Action(types.UPDATE_BRICK, { namespace: namespaces.FLOW }) updateBrick: any;

  // Getters
  @Getter(types.GET_BRICK_NAMES, { namespace: namespaces.BRICK }) getInstalledBricks: any;

  @Getter(types.GET_USER, { namespace: namespaces.USER }) getUser: any;

  // Data
  userdataCopy = $.extend(true, {}, this.userdata);

  SECRET_TYPE = SECRET_TYPE;

  EDITOR_TEXT_AREA = EDITOR_TEXT_AREA;

  EDITOR_STRING_LIST = EDITOR_STRING_LIST;

  expansionPanel: number[] = [1];

  show = false;

  parameterTypeError: Array<string> = ['', '', '', ''];

  parameterBeforeReset: Array<string> = ['', '', '', ''];

  textareaEditorDialog: boolean = false;

  stringListEditorDialog: boolean = false;

  parameterToEdit: Parameter = {
    name: '',
    type: '',
    value: '',
    unlocked: false,
  };

  parameterIndex: number = -1;

  decryptLoading: boolean = false;

  isParameterRequired = (parameter: Parameter) => {
    if (parameter && parameter.constraints && parameter.constraints.required) {
      return parameter.constraints.required;
    }
    return false;
  };

  public lifeCycle: Property[] = [];

  public previousLifeCycle: Property[] = [];

  public rules: any = {
    num: (v: any) => {
      const param: number = Number(v);
      // eslint-disable-next-line no-restricted-globals
      return !isNaN(param) || String(i18n.t('type_error_int'));
    },
  };

  // Vue Life Cycle Hooks
  created() {
    this.userdata.newName = this.brickName;

    this.initializeUserData();
  }

  // Watchers
  @Watch('userdata', { deep: true })
  onUserDataChanged() {
    this.initializeUserData();
  }

  // Computed
  get getLifeCycleProperties() {
    return this.lifeCycle;
  }

  get isBrickTypeAPI(): boolean {
    return this.userdataCopy.type === 'APIRequest' || this.userdataCopy.type === 'APIResponse';
  }

  // Methods
  initializeUserData() {
    this.userdataCopy = $.extend(true, {}, this.userdata);

    this.lifeCycle = [
      { name: 'max_inst', value: this.userdataCopy.autoscale_max_instances },
      { name: 'idle_time', value: this.userdataCopy.exit_after_idle_seconds },
      { name: 'queue_level', value: this.userdataCopy.autoscale_queue_level },
    ];

    this.previousLifeCycle = [
      { name: 'max_inst', value: this.userdata.autoscale_max_instances },
      { name: 'idle_time', value: this.userdata.exit_after_idle_seconds },
      { name: 'queue_level', value: this.userdata.autoscale_queue_level },
    ];
  }

  log = (message: string) => {
    logging('PropertyPane.vue', message);
  };

  // TODO: the save brick name always call two time. need to fix
  saveBrickName() {
    this.log('saveBrickName is called');
    this.$emit('save-settings-clicked');
  }

  /**
   * Prepares a parameter for editing by:
   *
   * - Displaying the edit parameter dialog
   * - Preparing props for the dialog to show
   *
   * @param parameter - The parameter that has to be
   * edited
   * @param index - The index on which the parameter
   * is inside @member userData.parameters
   * @param type - Type of parameter according to which
   * the dialog is to be opened
   */
  async editParameter(parameter: Parameter, index: number, type: string = EDITOR_TEXT_AREA) {
    if (parameter.type === SECRET_TYPE) {
      await this.decrypt(parameter);
    }

    this.parameterToEdit = parameter;
    this.parameterIndex = index;

    switch (type) {
      case EDITOR_TEXT_AREA:
        this.textareaEditorDialog = true;
        break;

      case EDITOR_STRING_LIST:
        this.stringListEditorDialog = true;
        break;

      default:
        this.textareaEditorDialog = true;
    }
  }

  /**
   * Turns off edit parameter dialog and saves
   * the edited parameter value in the state
   *
   * @param parameter - The parameter that has to be
   * edited (It is sent with a new value from the dialog)
   * @param index - The index on which the parameter
   * is inside @member userData.parameters
   */
  setParameterValue(parameter: Parameter, index: number) {
    // this.putNewParameterValue(parameter, index);
    this.textareaEditorDialog = false;
  }

  /**
   * Finds the default value from the installed
   * brick in the package manager and replaces
   * the parameter with the default value
   *
   * @param parameter - The parameter that has to be
   * reset back to its default
   * @param index - The index on which the parameter
   * is inside @member userData.parameters
   */
  toDefault(parameter: Parameter, index: number) {
    this.log('toDefault is called');

    // find installed brick
    const brick = this.getInstalledBricks.find((b: any) => b.id === this.userdataCopy.id);

    // find the original parameter
    const original: Parameter = brick.parameters[index];

    // replace with default installed value
    this.$set(parameter, 'value', original.value);
  }

  /**
   * Finds the default value from the installed
   * brick in the package manager and replaces
   * the parameter with the default value
   *
   * @param parameter - The parameter that has to be
   * reset back to its default
   * @param index - The index on which the parameter
   * is inside @member userData.parameters
   */
  toDefaultProperty(property: Property, index: number) {
    this.log('toDefaultProperty is called');

    // find the original parameter
    const original: Property = this.previousLifeCycle[index];

    // replace with default installed value
    this.$set(property, 'value', original.value);
  }

  getBrickIcon = (brickFamily: string, brickimage: string) => getBrickIcon(brickFamily, brickimage);

  /**
   * Validates the given parameter by checking any
   * config that was installed with the brick.
   *
   * - Checks if parameter is required
   * - Checks if parameter has to match a regex
   *
   * @param parameter - The parameter that has to be
   * validated
   * @param index - The index on which the parameter
   * is inside @member userData.parameters
   */
  validateParameterRules(parameter: Parameter) {
    const rules = [];

    // check if parameter has a required rule
    if (this.isParameterRequired(parameter)) {
      rules.push((v: any) => !!v || `${parameter.name} ${String(i18n.t('required_field'))}`);
    }

    // stop operation here if parameter is secret and locked
    if (parameter.unlocked && parameter.type === SECRET_TYPE) return rules;

    // validate the parameter with any given RegEx in the brick
    if (!validateParameter(parameter)) {
      rules.push(
        (v: any) => `${String(i18n.t('match_regex'))} ${parameter.constraints?.validation}}`,
      );
    }

    return rules;
  }

  /**
   * Decrypts the parameter to
   * display it to the user
   *
   * @param parameter - the parameter
   * that needs decryption
   */
  async decrypt(parameter: Parameter) {
    if (!parameter.unlocked) {
      if (parameter.value === '') {
        this.$set(parameter, 'unlocked', true);
      } else {
        this.decryptLoading = true;
        if (!this.userdata.instanceId) {
          console.error('instanceId does not exist during the decryp parameter');
          this.decryptLoading = false;
          return;
        }
        await DecryptParameter(this.flowID, this.userdata.instanceId, parameter.name)
          .then(({ data }) => {
            if (data) {
              this.$set(parameter, 'unlocked', true);
              this.$set(parameter, 'value', data);

              this.show = true;
            }
          })
          .catch(({ response }) => {
            this.setError({ error: response.data, level: 2 });
          });

        this.decryptLoading = false;
      }
    }
  }

  /**
   * Updates the parameter by saving it
   * to the DB
   *
   * @param parameter - The parameter that was changed
   * and needs to be saved
   * @param index - The index where it exists
   * in @member userDataCopy.parameters
   * @param isFromParameterEditor - indicates that @param parameter
   * comes from the Parameter Editor Dialog
   *
   */
  async updateParameter(parameter: Parameter, index: number, isFromParameterEditor = false) {
    let rules: any = [];

    if (isFromParameterEditor) {
      this.userdataCopy.parameters[index] = parameter;
    } else {
      // trim value to avoid spaces in empty string
      if (parameter.type !== 'bool' && parameter.type !== SECRET_TYPE) {
        this.$set(parameter, 'value', parameter.value.trim());
      }

      if (parameter.type === 'bool') {
        // toggle the value
        this.$set(parameter, 'value', parameter.value.toString() === 'true' ? 'false' : 'true');
      }
      // find the original parameter
      const original: Parameter = this.userdata.parameters[index];

      // stop operation if value did not change
      if (parameter.value === original.value) {
        return;
      }

      // run the rules for the parameter to check if valid
      rules = this.validateParameterRules(parameter).map((rule: any) => rule(parameter.value));

      // there are cases when some parameters are
      // invalid and they need to be replaced with
      // their original values so nothing dirty goes
      // into the DB
      this.userdataCopy.parameters.map((param, i) => {
        if (i === index) return param;

        if (!validateParameter(param)) {
          const orig: Parameter = this.userdata.parameters[i];

          this.$set(param, 'value', orig.value);
        }

        return param;
      });
    }

    // save brick
    if (
      (rules.length === 1 && typeof rules[0] === 'boolean')
      || rules.length === 0
      || isFromParameterEditor
    ) {
      await this.updateBrick({ brick: this.userdataCopy, isShowSuccess: true });

      this.$emit('update-user-data');
    }
  }

  /**
   * Updates a property in a brick
   *
   * @param property - one of the three properties found
   * in the @member lifeCycle
   * @param index - the index where it exists in the
   * @member lifeCycle AND @member previousLifeCycle
   */
  async updateProperty(property: Property, index: number) {
    if (!parseInt(property.value.toString(), 10)) {
      return;
    }

    const original: Property = this.previousLifeCycle[index];
    const value = parseInt(property.value.toString(), 10);

    // stop operation if value did not change
    if (value === original.value) {
      return;
    }

    switch (index) {
      case 0:
        this.userdataCopy.autoscale_max_instances = value;

        this.userdataCopy.exit_after_idle_seconds = this.previousLifeCycle[1].value;
        this.userdataCopy.autoscale_queue_level = this.previousLifeCycle[2].value;

        break;
      case 1:
        this.userdataCopy.exit_after_idle_seconds = value;

        this.userdataCopy.autoscale_max_instances = this.previousLifeCycle[0].value;
        this.userdataCopy.autoscale_queue_level = this.previousLifeCycle[2].value;

        break;
      default:
        this.userdataCopy.autoscale_queue_level = value;

        this.userdataCopy.autoscale_max_instances = this.previousLifeCycle[0].value;
        this.userdataCopy.exit_after_idle_seconds = this.previousLifeCycle[1].value;
    }

    await this.updateBrick({ brick: this.userdataCopy, isShowSuccess: true });

    this.$emit('update-user-data');
  }
}
