
import { validateParameter } from '@/scripts/editor/helper';
import { Parameter } from '@/store/bricks/models';
import { GET_USER, SET_ERROR } from '@/store/types';
import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import 'text-security';
import { SECRET_TYPE } from '@/scripts/shared';

const namespace = 'user';
const namespaceError = 'error';

@Component
export default class TextareaEditor extends Vue {
  // Props
  @Prop() parameter!: Parameter;

  @Prop() index!: number;

  @Prop() flowOwner!: string;

  @Prop() flowId!: string;

  @Prop() instanceId!: string;

  @Prop() isParameterRequired!: Function;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  // Getters
  @Getter(GET_USER, { namespace }) getUser: any;

  // Data
  private show: boolean = false;

  private value: string = '';

  private encryptedValue: string = '';

  private oldValue: string = '';

  private valid = false;

  private SECRET_TYPE = SECRET_TYPE;

  private parameterCopy: Parameter = {
    name: '',
    type: '',
    unlocked: true,
    value: '',
  };

  private rules: ((value: string) => string | true)[] = [];

  // Vue Lifecycle Hooks
  created() {
    this.parameterCopy = $.extend(true, {}, this.parameter);

    this.rules = [
    // REQUIRED RULE
      (value: string) => {
        if (this.isParameterRequired(this.parameterCopy)) {
          return !!value || `${this.parameterCopy.name} ${String(this.$t('required_field'))}`;
        }

        return true;
      },

      // REGEX RULE
      (value: string) => {
      // skip if type is secret and is locked
        if (!this.parameterCopy.unlocked && this.parameterCopy.type === SECRET_TYPE) return true;

        // else validate regex
        const { parameterCopy } = this;
        parameterCopy.value = value;
        if (!validateParameter(parameterCopy)) {
          return `${String(this.$t('match_regex'))} ${this.parameterCopy.constraints?.validation}`;
        }

        return true;
      },
    ];
  }

  // Methods

  private close() {
    this.$emit('close');
  }

  /**
   *
   * @param emitName: The emit function to call
   */
  private async updateParameter() {
    // trim value to avoid spaces in empty string
    if (this.parameterCopy.type !== 'bool' && this.parameterCopy.type !== SECRET_TYPE) {
      this.$set(this.parameterCopy, 'value', this.parameterCopy.value.trim());
    }

    // stop operation if value did not change
    if (this.parameterCopy.value === this.parameter.value) {
      this.$emit('close');
      return;
    }

    const { form }: any = this.$refs;

    // validate form to see if there are any errors
    form.validate();

    if (this.valid) {
      this.$emit('update-parameter', this.parameterCopy, this.index, true);

      this.$emit('close');
    }
  }
}
