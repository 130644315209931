
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Port } from '@/store/bricks/models';
import * as types from '@/store/types';
import { makeTreeviewItem } from '@/scripts/bricks/addPorts';

const namespace: string = 'flow';

@Component
export default class PortEditor extends Vue {
 @Prop() dialog!: boolean;

 @Prop() port!: any;

 @Prop() brick!: any;

 @Prop({ default: false }) isFlowReadOnly! : boolean;

 @Action(types.CREATE_PORT_JSON, { namespace }) createPortJSON: any;

 @Getter(types.GET_PORT, { namespace }) getPort: any;

 private newSchema : string = this.port.schema;

 async saveSchema() {
   await this.createPortJSON({
     name: this.port.name,
     typeName: this.port.dataTypeName,
     JSONSchema: this.port.JSONSchema,
     mappingRules: [],
     schema: this.newSchema,
   });
   // get what the flowmanger returned
   const port = this.getPort;
   // put it to the canvas elements
   // to port
   this.port.populatedJSON = port.populatedJSON;
   this.port.JSONSchema = port.JSONSchema;
   this.port.schema = port.schema;
   this.port.mappingRules = [];

   // re-create port information
   const definition : any = makeTreeviewItem(port.populatedJSON);
   this.port.typeDef = definition;
   this.port.typeDoc = port.populatedJSON.doc;

   // to bricks
   if (this.port.type === 'output') {
     const iport = this.brick.outputPorts.findIndex((p:Port) => p.name === this.port.name);
     this.brick.outputPorts[iport] = port;
   }
   if (this.port.type === 'input') {
     const iport = this.brick.inputPorts.findIndex((p:Port) => p.name === this.port.name);
     this.brick.inputPorts[iport] = port;
   }
   this.$emit('close');
 }
}
