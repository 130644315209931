
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { ErrorLevel } from '@/store/errors/models';
import * as webSocket from '@/middlewares/websocketConfig';
import Toolbar from '@/components/shared/Toolbar.vue';
import FailedMessage from '@/components/shared/FailedMessage.vue';

const namespace: string = 'error';

@Component({
  components: {
    Toolbar, FailedMessage,
  },
})
export default class LogPane extends Vue {
  @Prop()
  flow: any;

  @Action(types.SET_ERROR, { namespace })
  setError: any;

  public ws: any;

  private logs: any = [];

  private filteredLogs: any = [];

  private filterLevel: string = 'ALL';

  private filterBricks: string = '';

  private brick: string = '';

  private failedToGetLog: string = '';

  // eslint-disable-next-line class-methods-use-this
  getSeverityColor(severity: string) {
    switch (severity.toUpperCase()) {
      case 'WARNING':
        return 'warning';
      case 'ERROR':
        return 'error';
      case 'DEBUG':
        return 'info';
      default:
        return 'green';
    }
  }

  created() {
    this.ws = new WebSocket(`${webSocket.BASE_URL}:${webSocket.PORT}${webSocket.PATH_EXTENSION}`);

    this.ws.onmessage = (event: any) => {
      this.parseMessage(event.data);
    };

    const request = {
      payload: {
        FlowUID: this.flow.id,
      },
    };

    this.ws.onopen = (event: any) => {
      this.ws.send(JSON.stringify(request));

      this.setError({
        error: 'Connected to log service',
        level: ErrorLevel.INFO,
      });
    };

    this.ws.onerror = (event: any) => {
      this.setError({
        error: 'Not Connected to log service',
        level: ErrorLevel.ERROR,
      });
      this.failedToGetLog = 'Could not Connected to the log service';
    };
  }

  private parseMessage(message: string) {
    const msgObj = JSON.parse(message);
    const {
      Timestamp,
      Severity,
      Hostname,
      Source,
      FlowName,
      BrickName,
      Message,
    } = msgObj.payload;

    this.logs.unshift({
      Timestamp,
      Severity,
      Hostname,
      Source,
      FlowName,
      BrickName,
      Message,
    });

    this.applyFilter();
  }

  beforeDestroy() {
    // checking open state
    if (this.ws.readyState === 1) {
      const request = {
        payload: 'stop',
      };

      this.ws.send(JSON.stringify(request));
    }

    this.setError({
      error: 'Disconnected from log service',
      level: ErrorLevel.INFO,
    });

    this.ws.close();
  }

  resetFilter() {
    this.filterLevel = 'ALL';
    this.filterBricks = '';
    this.filteredLogs = this.logs;
  }

  applyFilter() {
    this.filteredLogs = this.logs;

    // check for filter level if any
    if (this.filterLevel !== 'ALL') {
      this.filteredLogs = this.filteredLogs.filter((log: any) => log.Severity === this.filterLevel);
    }

    // check for brick filter
    if (this.filterBricks && this.filterBricks !== '') {
      this.filteredLogs = this.filteredLogs.filter(
        (log: any) => log.BrickName === this.filterBricks,
      );
    }
  }

  private closePane() {
    this.$emit('close-pane');
  }
}
