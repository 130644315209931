
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { MESSAGE_TYPE_REGEX, TYPE_ID_PREFIX, UINT_REGEX } from '@/scripts/shared';
import { SchemaType } from '@/scripts/shareModels/schema';
import * as nanoid from 'nanoid';
import { AtomicTypes } from '@/scripts/shareModels/types';
import { BrickInFlow } from '@/store/flow/models';
import { namespaces } from '@/scripts/namespaces';
import { TypeLibrary } from '@/store/typeLibrary/models';
import { generateParentChainForSchema, isReservedType } from './scripts';

const flowNamespace: string = 'flow';

@Component
export default class AddFieldInMap extends Vue {
  // Props
  @Prop() item!: SchemaType;

  @Prop() schemaToEdit!: SchemaType;

  @Prop() brick!: BrickInFlow;

  @Prop() port!: any;

  // Getters

  // Actions
  @Action(types.ADD_FIELD_TO_MAP, { namespace: flowNamespace }) addFieldToMap: any;

  @Action(types.EDIT_FIELD_IN_MAP, { namespace: flowNamespace }) editFieldInMap: any;

  @Action(types.RESOLVED_TYPE, { namespace: namespaces.TYPE_LIBRARY }) getResolvedType: any;

  @Getter(types.GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypesLibrary: any;

  // Data
  private valid: boolean = false;

  private name: string = '';

  private nameRules: any = {
    required: (v: string) => !!v || this.$t('required_field'),
    textRule: (v: string) => MESSAGE_TYPE_REGEX.test(v) || this.$t('message_type_invalid'),
    conflictRule: (v: string) => this.checkNameConflict(v),
  };

  private selectedType: string = '';

  private selectedKeyValueType: string = '';

  private isEditMode: boolean = false;

  workspaceId = this.$route.params.workspaceId;

  // Computed

  get TypesInEditMode() {
    if (this.schemaToEdit && !this.schemaToEdit.isCustom) {
      const extraType = [{ id: 'map', name: 'map' }, { id: 'variant', name: 'variant' }];
      return this.typesLibraryForPortElement.concat(extraType);
    }

    const availaleTypes = this.reservedTypes();

    if (this.getTypesLibrary) {
      return [...availaleTypes, ...this.getTypesLibrary];
    }
    return availaleTypes;
  }

  get TypesInAddMode(): any {
    if ((this.item && this.item.key_value_types && !this.isEditMode)
      || (this.item && this.item.type === 'variant' && !this.isEditMode)) {
      const type = this.item.key_value_types ? this.item.key_value_types[1] : this.item.type;
      let availableTypes: any = [];
      const isTypeReserved = this.reservedTypes().find((r: any) => r.name === type);
      if (isTypeReserved) {
        if (type === 'variant') {
          availableTypes = [...availableTypes, ...this.reservedTypes()];
        } else {
          const originalType = {
            id: type,
            name: type,
          };
          availableTypes.push(originalType);
        }
      }

      const typeLibrary = this.getTypeLibraryByType(type);
      if (typeLibrary) {
        availableTypes = [...availableTypes, ...typeLibrary];
      }

      return availableTypes;
    }
    return [];
  }

  get types(): any {
    if (!this.isEditMode) {
      return this.TypesInAddMode;
    }
    return this.TypesInEditMode;
  }

  get listTypeMap(): any[] {
    const reservedTypes = this.reservedTypes();

    if (this.getTypesLibrary) {
      return [...reservedTypes, ...this.getTypesLibrary];
    }
    return reservedTypes;
  }

  get typesLibraryForPortElement() {
    if (this.getTypesLibrary) {
      const res = this.getTypesLibrary.filter(
        (t: TypeLibrary) => t.type === 'object' || t.type === 'map',
      );
      return res;
    }
    return [];
  }

  get isCustomType() {
    return this.schemaToEdit && this.schemaToEdit.isCustom;
  }

  // Vue Life Cycle Hooks

  mounted() {
    if (this.schemaToEdit.name && this.schemaToEdit.type) {
      this.name = this.schemaToEdit.name;
      this.selectedType = this.schemaToEdit.type;

      if (this.selectedType === 'map' && this.schemaToEdit.key_value_types) {
        [, this.selectedKeyValueType] = this.schemaToEdit.key_value_types;
      }

      this.isEditMode = true;

      this.$nextTick(() => {
        const { form }: any = this.$refs;

        form.validate();
      });
    } else {
      this.resetForm();
      this.isEditMode = false;
    }
  }

  // Methods
  /**
   * Empties all fields in the form
   */
  resetForm() {
    this.name = '';
    this.selectedType = '';
  }

  /**
   * Adds or Edits the new field
   * into the store and resets the form
   */
  async addOrEditField() {
    const { form }: any = this.$refs;

    form.validate();

    if (this.valid) {
      // Initialize Field
      const schema: SchemaType = {
        id: this.isEditMode ? this.schemaToEdit.id : `${TYPE_ID_PREFIX}${nanoid.nanoid()}`,
        name: this.name,
        type: this.selectedType,
        key_value_types: this.selectedType === 'map' ? ['string', this.selectedKeyValueType] : [],
        parents: this.isEditMode
          ? this.schemaToEdit.parents
          : this.item.parents?.concat([this.item.name]),
      };

      if (this.isEditMode) {
        schema.isCustom = this.schemaToEdit.isCustom;
        const resolveType = await this.resolveTypes(schema);
        this.editFieldInMap({
          brick: this.brick,
          port: this.port,
          item: resolveType,
        });
      } else {
        const resolveType = await this.resolveTypes(schema);
        resolveType.isCustom = true;

        this.addFieldToMap({
          schema: resolveType,
          itemToAddTo: this.item,
        });
      }

      // Reset form and validation
      form.reset();
      this.resetForm();
      this.$emit('cancel');
    }
  }

  /**
   * Check Name Conflict with other fields
   */
  checkNameConflict(name: string) {
    if (this.item && this.item.elements) {
      const { elements } = this.item;

      const isValid = elements
        .filter((e: SchemaType) => e.name !== (this.schemaToEdit ? this.schemaToEdit.name : '')) // ignore current name when editing
        .findIndex((e: SchemaType) => e.name === name);

      if (isValid >= 0) {
        return this.$t('name_already_in_use');
      }
    }

    return true;
  }

  getTypeLibraryByType(type: string) {
    if (this.getTypesLibrary) {
      if (type === 'variant') {
        return this.getTypesLibrary;
      }

      const findTypes = this.getTypesLibrary.filter(
        (t: TypeLibrary) => t.id === type || t.type === type,
      );

      if (findTypes) {
        return findTypes;
      }
    }

    return '';
  }

  async resolveTypes(schema: any) {
    const isReserved = isReservedType(schema.type);

    if (isReserved) return schema;

    const typeToResolve = {
      workspaceId: this.workspaceId,
      typeId: schema.type,
    };
    const res: SchemaType = await this.getResolvedType(typeToResolve);

    res.id = schema.id;
    res.typeLibraryName = res.name;
    res.name = schema.name;
    res.isTypeLibrary = true;

    const resolveParents = generateParentChainForSchema(res, schema.parents);

    return resolveParents;
  }

  reservedTypes = () => {
    const atomic = AtomicTypes.concat(['map']).map((t) => ({
      id: t,
      name: t,
    }));
    return atomic;
  };
}
